<template>
  <div id="consumption-records">
    <div class="container">
      <div class="page-title">
        消費紀錄
      </div>
      <div class="operation-btns">
        <div
          v-for="(consumptionStatus, index) in consumptionStatuses"
          :key="consumptionStatus.value"
          class="operation-btn"
          :class="statusClass(consumptionStatus.value)"
          @click="chagneStatus(consumptionStatus.value)"
        >
          <div
            class="btn-text"
            :class="statusClass(consumptionStatus.value)"
          >
            {{ consumptionStatus.label }}
          </div>
          <div
            v-if="index === 0 && count > 0"
            class="bubble-icon"
          >
            <div class="bubble">
              {{ count }}
            </div>
          </div>
        </div>
      </div>
      <div class="records-wrapper">
        <div v-if="consumptions.length === 0">
          無資料
        </div>
        <div v-else>
          <div
            v-for="(record, index) in consumptions"
            :key="index"
            class="record-block"
            @click="toRecordDetailPage(record.id)"
          >
            <div class="btn-left">
              <div class="left-item order-no">
                {{ record.serial }}
              </div>
              <div class="left-item store-name">
                {{ record.store_name }}
              </div>
              <div class="left-item buy-date">
                購買日期：{{ record.purchaseDate }}
              </div>
            </div>
            <div class="btn-right">
              <img
                class="right-arrow"
                :src="require('@/assets/image/icon-right.svg')"
              >
              <div
                class="record-status"
                :class="handleStatusClass(record.status)"
              >
                {{ getStatusText(record.status) }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <MemberBtn
      class="big-btn"
      :is-active="value"
    />
  </div>
</template>

<script>
import _ from 'lodash'
import { mapGetters } from 'vuex'

import MemberBtn from '@/components/global/MemberBtn'
export default {
  components: {
    MemberBtn
  },
  data() {
    return {
      value: 1,
      isActive: 1,
      count: 0,
      currentStatus: 'pending'
    }
  },
  computed: {
    ...mapGetters({
      consumptions: 'consumption/list'
    }),
    consumptionStatuses() {
      return [
        {
          value: 'pending',
          label: '待處理'
        },
        {
          value: 'checking',
          label: '審核中'
        },
        {
          value: 'completed',
          label: '已完成'
        }
      ]
    }
  },
  watch: {
    $route() {
      this.init()
    }
  },
  async mounted() {
    await this.init()
  },
  methods: {
    async init() {
      this.$root.loading = true
      await this.$store.dispatch('consumption/resetList')

      const status = _.get(this.$route, 'query.status', 'pending')
      this.currentStatus = status

      const data = {
        status: this.getStatus(status)
      }
      await this.$store.dispatch('consumption/fetchList', { data })
      this.currentStatus === 'pending' ? this.count = this.consumptions.length : this.count = 0
      this.$root.loading = false
    },
    getStatus(status) {
      switch (status) {
        case 'pending':
          return [1, 3]
        case 'checking':
          return [2]
        case 'completed':
          return [4, 5]
      }
    },
    toRecordDetailPage(no) {
      this.$router.push(`/ms-web/consumption-records/${no}`)
    },
    getStatusText(status) {
      switch (status) {
        case 1:
          return '待上傳發票'
        case 2:
          return '已上傳發票'
        case 3:
          return '未通過'
        case 4:
          return '已通過'
        case 5:
          return '已結束'
        default:
          return ''
      }
    },
    chagneStatus(status) {
      this.$router.push(`/ms-web/consumption-records?status=${status}`)
    },
    statusClass(status) {
      if (status === this.currentStatus) {
        return 'is-active'
      }

      return ''
    },
    handleStatusClass(status) {
      if ([1, 3, 5].includes(status)) {
        return 'is-red'
      }
    }
  }
}
</script>

<style lang="scss" scoped>
#consumption-records {
  .operation-btns {
    display: flex;
    flex-direction: row;
    padding-bottom: 20px;
  }
  .operation-btn {
    position: relative;
    width: 100%;
    border: 1px solid $text-1;
    border-radius: 3px;
    padding: 4px 18px;
    border-color: $text-1;

    cursor: pointer;
    transition: all 0.3s;

    .btn-text {
      color: $text-1;
      font-weight: bold;

      &.is-active, &:hover {
        border-color: $primary;
        color: $primary;
      }
    }

    &:not(:last-of-type) {
      margin-right: 2px;
    }

    &.is-active, &:hover {
      border-color: $primary;
      color: $primary;
    }
  }
  .record-block {
    padding: 10px 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border-bottom: 1.5px solid $background-light;
  }
  .btn-left {
    text-align: left;
  }
  .left-item {
    color: $text-1;
  }
  .order-no, .buy-date, .record-status {
    font-size: 14px;
  }
  .store-name {
    font-size: 17px;
    font-weight: bold;
    padding-bottom: 10px;
  }
  .btn-right {
    display: flex;
    flex-direction: column-reverse;
    align-items: flex-end;
    justify-content: space-between;
  }
  .right-arrow {
    width: 8px;
    padding-bottom: 5px;
  }
  .record-status {
    color: $text-1;
    background-color: $background-light;
    padding: 1px 5px;
    font-weight: bold;
    &.is-red {
      color: red;
    }
  }
  .bubble-icon {
    position: absolute;
    top: -8px;
    left: -6px;
  }
  .bubble {
    height:18px;
    width:18px;
    border-radius: 50%;
    background-color: red;
    box-shadow: 2px 2px 1px 1px rgba(20, 80, 209, 0.2);

    color: #ffffff;
    font-size: 12px;
    font-weight: bold;
    line-height: 18px;
  }
}
</style>
