// const apiBaseUrl = process.env.VUE_APP_API_BASE_URL || 'https://project-ms-web-app-api-dev-01.azurewebsites.net/api'
// const backendBaseUrl = process.env.VUE_APP_BACKEND_BASE_URL || 'https://project-ms-web-app-api-dev-01.azurewebsites.net'
// const qrcodeRedirectUrl = process.env.VUE_APP_QRCODE_REDIRECT_URL || 'https://liff.line.me/1655444779-zBjnJVY1'
// const addFriendShipmentUrl = process.env.VUE_APP_ADD_FRIEND_SHIPMENT_URL || 'https://lin.ee/KQ6LPXV'
const apiBaseUrl = 'https://project-ms-web-app-api-prd-01.azurewebsites.net/api'
const backendBaseUrl = 'https://project-ms-web-app-api-prd-01.azurewebsites.net'
const qrcodeRedirectUrl = 'https://liff.line.me/1655568204-Dn0VGgE2'
const addFriendShipmentUrl = 'https://lin.ee/KQ6LPXV'

export {
  apiBaseUrl,
  backendBaseUrl,
  qrcodeRedirectUrl,
  addFriendShipmentUrl
}
