import Vue from 'vue'
import axios from 'axios'
// import VueCookies from 'vue-cookies'
import VueToast from 'vue-toast-notification'
import _ from 'lodash'

import App from './App.vue'

import store from '@/store'
import router from '@/router'
import vuetify from '@/plugins/vuetify'
import { errorDictionary } from '@/plugins/error'
import vConsole from '@/plugins/vconsole'

import { getJwtToken, setJwtToken, removeJwtToken } from '@/utils/api/methods'
import { apiBaseUrl } from '@/utils/api/variable'

import Btn from '@/components/global/Btn'
import VInput from '@/components/global/VInput'
import Dialog from '@/components/global/Dialog'
import NoticeDialog from '@/components/global/NoticeDialog'
import OfflineDialog from '@/components/global/OfflineDialog'
import ForgetPasswordDialog from '@/components/global/ForgetPasswordDialog'
import PageTitle from '@/components/global/PageTitle'
import CImage from '@/components/global/CImage'

import api from '@/utils/api'
import helpers from '@/utils/helpers'

import 'vue-toast-notification/dist/theme-sugar.css'

Vue.config.productionTip = false

Vue.component('Btn', Btn)
Vue.component('VInput', VInput)
Vue.component('Dialog', Dialog)
Vue.component('NoticeDialog', NoticeDialog)
Vue.component('OfflineDialog', OfflineDialog)
Vue.component('ForgetPasswordDialog', ForgetPasswordDialog)
Vue.component('PageTitle', PageTitle)
Vue.component('CImage', CImage)

const isProduction = true
if (!isProduction) {
  Vue.use(vConsole)
}
// Vue.use(VueCookies)
Vue.use(VueToast, {
  position: 'top'
})

var app = new Vue({
  data: { loading: false },
  router,
  vuetify,
  store,
  // created() {
  //   return (function(d, s, id){
  //     var js, fjs = d.getElementsByTagName(s)[0]
  //     if (d.getElementById(id)) {return}
  //     js = d.createElement(s)
  //     js.id = id
  //     js.src = "//connect.facebook.com/en_US/messenger.Extensions.js"
  //     fjs.parentNode.insertBefore(js, fjs)
  //   }(document, 'script', 'Messenger'))
  // },
  render: h => h(App)
}).$mount('#app')

Vue.prototype.$api = api
Vue.prototype.$error = {
  getMessage: (key) => errorDictionary[key] || key
}

Vue.mixin({
  beforeCreate() {
    this.$helpers = helpers
  }
})

let apiRetry = false

axios.interceptors.response.use((response) => {
  // console.log('apiRetry response', response)
  apiRetry = false

  return response
}, async(error) => {
  const originalConfig = error.config
  // console.log('error', error)
  // console.log('originalConfig', originalConfig)
  const { status, data } = error.response

  // console.log('error data', data)
  const isTokenExpired = data.message === 'TOKEN_IS_EXPIRED'
  // console.log('status === 401 && !apiRetry && isTokenExpired', status === 401 && !apiRetry && isTokenExpired)

  console.log('isTokenExpired')
  console.log('apiRetry', apiRetry)
  if (status === 401 && data.message === 'TOKEN_IS_INVALID') {
    // console.log('TOKEN_IS_INVALID')
    removeJwtToken()
  } else if (status === 401 && !apiRetry && isTokenExpired) {
    // console.log('REFRESH_TOKEN')

    const token = getJwtToken()
    if (!token) {
      return Promise.reject(error)
    }

    try {
      const rs = await axios({
        url: `${apiBaseUrl}/auth/refresh`,
        method: 'POST',
        headers: {
          Authorization: 'Bearer ' + token
        }
      })

      // console.log('rs', rs)
      const accessToken = _.get(rs, 'data.data.access_token')
      // console.log('accessToken', accessToken)
      if (!accessToken) {
        return Promise.reject(error)
      }

      setJwtToken(accessToken)
      apiRetry = true

      return axios(originalConfig)
    } catch (_error) {
      return Promise.reject(_error)
    }
  }

  if (isTokenExpired) {
    // console.log('still isTokenExpired')
  }

  app.$toast.open({
    message: errorDictionary[data.message] || data.message,
    type: 'error'
  })

  // router.push({ name: 'Login' })

  return Promise.reject(error)
})

// router.beforeEach(async(to, from, next) => {
//   console.log('to, from', to, from);
//   app.loading = true
//   console.log('store', store)
//   const { requireAuth } = to.meta || {}
//   console.log('requireAuth', requireAuth)
//   const isLogin = _.get(store, 'state.user.data.created_at')
//   const token = getJwtToken()

//   if (requireAuth) {
//     if (token && !isLogin) {
//       await store.dispatch('user/fetchData').then(() => {
//         console.log('fetch')
//       }).catch(err => {
//         console.log('err', err)
//       })
//     }
//   }
//   next()
// })

// router.afterEach(() => {
//   setTimeout(() => { app.loading = false }, 300)
// })

Vue.prototype.$loading = {
  start: () => { app.loading = true },
  finish: () => setTimeout(() => { app.loading = false }, 300)
}
