<template>
  <div class="coupon">
    <div v-if="normailizeGifts.length === 0">
      未有優惠券
    </div>
    <div
      v-for="(gift, index) in normailizeGifts"
      :key="index"
      class="coupon-btn"
    >
      <!-- <v-icon
        v-if="edit"
        small
        color="danger"
        class="remove-icon"
        @click="() => handleRemove(gift.id)"
      >
        mdi-delete
      </v-icon> -->
      <div class="main-btn">
        <div class="image-wrapper">
          <img
            class="gift-image"
            :src="imageUrl(gift)"
          >
        </div>
        <div class="main-right">
          <div class="gift-label">
            {{ gift.name }}
          </div>
          <div
            v-if="gift.serial"
            class="gift-no"
          >
            兌換碼：{{ gift.serial }}
          </div>
          <div
            v-if="gift.point"
            class="gift-point"
          >
            {{ gift.point }} 點
          </div>
          <div
            v-else
            class="gift-note"
          >
            {{ gift.note }}
          </div>
        </div>
        <div class="btn-block">
          <div
            v-if="!used"
            :class="`receive-btn ${gift.is_expired ? 'is-expired' : ''}`"
            @click="handleClick(gift)"
          >
            兌換
          </div>
        </div>
      </div>
      <div class="blue-btn" />
    </div>
    <ExchangeDialogConfirm
      :is-open.sync="isOpenDialog"
      :data="currentGift"
    />
    <NoticeDialog
      :is-open.sync="isOpenNotice"
      :notice="notice"
    />
    <Dialog
      confirmation-text="優惠券已失效？"
      :is-open.sync="isOpenUsedDialog"
      @confirm="handleUsed"
      @close="currentCouponId = null"
    />
  </div>
</template>

<script>
// import { mapGetters } from 'vuex'
import _ from 'lodash'
import helpers from '@/utils/helpers'
import ExchangeDialogConfirm from '@/components/point/ExchangeDialogConfirm'
import NoticeDialog from '@/components/global/NoticeDialog'

export default {
  components: {
    ExchangeDialogConfirm,
    NoticeDialog
  },
  props: {
    gifts: {
      type: Array,
      default: () => []
    },
    coupons: {
      type: Array,
      default: () => []
    },
    myPoint: {
      type: Number,
      default: 0
    },
    used: {
      type: Boolean,
      default: false
    },
    store: {
      type: Boolean,
      default: false
    },
    isMyCoupon: {
      type: Boolean,
      default: false
    },
    isEdit: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isOpenDialog: false,
      isOpenNotice: false,
      isOpenUsedDialog: false,
      edit: '',
      currentGift: {},
      couponQuantity: 10,
      // myPoint: 10,
      // giftPoint: 35,
      currentCouponId: null
    }
  },
  computed: {
    normailizeGifts() {
      if (!this.store) {
        return this.gifts
      }

      if (this.used) {
        return this.coupons.filter((coupon) => coupon.is_used)
      }

      return this.coupons.filter((coupon) => !coupon.is_used)
    },
    notice() {
      if (this.couponQuantity <= 0) {
        return '此獎項暫時無法兌換'
      }
      if (this.myPoint < this.giftPoint) {
        return '您的紅利點數不足'
      }
      return ''
    }
  },
  watch: {
    isEdit() {
      this.edit = this.isEdit
    },
    edit() {
      this.$emit('update:isEdit', this.edit)
    }
  },
  methods: {
    imageUrl(gift) {
      const image_url = _.get(gift, 'award.photos.0.url', '')
      if (!image_url) return require('@/assets/image/icon-gift.svg')
      return helpers.getUrl(image_url)
    },
    handleClick(gift) {
      if (gift.is_expired) return
      if (this.isMyCoupon) {
        const link = gift.coupon_link || '-'
        window.open(link, '_blank')
        // this.isOpenDialog = true
        // this.currentGift = gift
        return
      }
      if (this.couponQuantity <= 0 || this.myPoint < this.giftPoint) {
        this.isOpenNotice = true
      } else {
        this.isOpenDialog = true
        this.currentGift = gift
      }
    },
    handleRemove(id) {
      this.isOpenUsedDialog = true
      this.currentCouponId = id
    },
    async handleUsed() {
      const data = {
        id: this.currentCouponId,
        data: {
          is_used: true
        }
      }
      await this.$store.dispatch('coupon/setUpdateDataToList', data)
      await this.$store.dispatch('coupon/refresh')
    }
  }
}
</script>

<style lang="scss" scoped>
.coupon {

  .remove-icon {
    padding-right: 5px;
  }
  .coupon-btn{
    height: 80px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 15px;

  }
  .main-btn {
    width: 100%;
    padding: 10px;
    display: flex;
    flex-direction: row;
    box-shadow: -3px 0px 15px $background-light;
    background-color: #ffffff;
  }
  .main-right {
    width: 100%;
    padding-left: 10px;
    flex-direction: column;
    display: flex;
  }
  .gift-image {
    width: 60px;
    height: 60px;
    overflow: hidden;
    border-radius: 6px;
    object-fit: cover;
  }
  .gift-label, .gift-note, .gift-no {
    text-align: left;
  }
  .gift-label {
    color: #3948A4;
    font-size: 18px;
    font-weight: bold;
  }
  .gift-point {
    color: $primary;
    font-size: 14px;
  }
  .gift-note {
    color: $text-2;
    font-size: 12px;
  }
  .gift-no {
    color: $text-2;
    font-size: 12px;
  }
  .receive-btn {
    display: flex;
    align-items: flex-end;
    padding: 4px 16px;
    font-size: 14px;
    font-weight: bold;
    background-color: #5AC5C0;
    border-radius: 14px;
    color: #fff;
    white-space: nowrap;
    &.is-expired {
      opacity: 0.6;
      cursor: not-allowed;
    }
  }
  .btn-block {
    display: flex;
    align-items: center;
  }
  .blue-btn {
    width:12px;
    background: linear-gradient(0deg, #1150D1 19%, #458BE0 67%);
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
  }
  &::v-deep .v-btn {
    margin-top: 40px;
    min-width: auto !important;
  }
}
</style>
