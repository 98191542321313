import _ from 'lodash'
import api from '@/utils/api'
import moment from 'moment'

// pending 待上傳發票
// failed 未通過
// checking 審核中
// completed 已通過

// Failed Reason
// blurred 照片模糊
// incompatible 發票上商品品項不符

const transData = (item = {}) => {
  return {
    ...item,
    purchaseDate: moment(item.created_at).format('YYYY-MM-DD HH:mm:ss')
  }
}

const state = () => ({
  data: {},
  list: [],
  pendingList: []
})

const getters = {
  data(state) {
    return transData(state.data)
  },
  list(state) {
    return state.list.map(item => transData(item))
  },
  pendingCount(state) {
    return (state.pendingList || []).length
  }
}

const mutations = {
  setData(state, data) {
    state.data = data
  },
  setList(state, list) {
    state.list = list
  },
  setPendingList(state, list) {
    state.pendingList = list
  },
  setUpdateDataToList(state, { no, data }) {
    const index = state.list.reduce((carry, item, index) => {
      if (item.no === no) {
        carry = index
      }

      return carry
    }, 0)

    if (index <= 0) {
      return
    }

    state.list[index] = {
      ...state.list[index],
      ...data
    }
  }
}

const actions = {
  setUpdateDataToList({ commit }, { no, data }) {
    commit('setUpdateDataToList', { no, data })
  },
  setData({ commit }, data) {
    commit('setData', data)
  },
  resetList({ commit }) {
    commit('setData', [])
  },
  async fetchList({ commit }, { data }) {
    const formData = data
    try {
      const response = await api('GET_ORDERS', { data: formData })
      const { status, data } = response.data
      if (status) {
        const list = _.get(data, '0.data', [])
        commit('setList', list)
      }
    } catch (error) {
      console.log('error', error)
    }
  },
  async fetchPendingList({ commit }) {
    const formData = {
      status: [1, 3]
    }
    try {
      const response = await api('GET_ORDERS', { data: formData })
      const { status, data } = response.data
      if (status) {
        const list = _.get(data, '0.data', [])
        commit('setPendingList', list)
      }
    } catch (error) {
      console.log('error', error)
    }
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
