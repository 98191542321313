export default {
  methods: {
    toLine() {
      const url = process.env.VUE_APP_LINE_LINK || 'https://lin.ee/vTyfOdI'
      window.open(url, '_blank')
    },
    toFacebook() {
      const url = process.env.VUE_APP_FACEBOOK_LINK || 'https://www.facebook.com/WindowsTaiwan'
      window.open(url, '_blank')
    }
  }
}
