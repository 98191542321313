import { apiBaseUrl } from '@/utils/api/variable'

const getMyCoupons = (context, action, requestItems) => {
  const { data = {}} = requestItems

  return ({
    // url: `${apiBaseUrl}/auth/award/changeRecord`,
    url: `${apiBaseUrl}/auth/award/couponList`,
    method: 'POST',
    data: {
      status: data.status
    }
  })
}

export default {
  GET_MY_COUPONS: getMyCoupons
}
