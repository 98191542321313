<template>
  <div class="detail container">
    <PageTitle back="/ms-web/point-award">
      紅利兌換好禮
    </PageTitle>
    <div class="title-wrapper">
      <div class="image-wrapper">
        <img :src="getImage(awardData)">
      </div>
      <div class="title-block">
        <div
          v-if="awardData.method_id == 1"
          class="type exchange"
        >
          兌換
        </div>
        <div
          v-if="awardData.method_id == 2"
          class="type online"
        >
          立即抽
        </div>
        <div
          v-if="awardData.method_id == 3"
          class="type offline"
        >
          幸運抽
        </div>
        <div class="award-name">
          {{ awardData.name }}
        </div>
        <div class="point-wrapper">
          <img
            class="point-icon"
            src="@/assets/image/point-icon-new.svg"
          >
          <div class="point">
            {{ awardData.point }}
          </div>
        </div>
      </div>
    </div>
    <div class="detail-block">
      <div class="end-block">
        <div class="award-name">
          活動截止時間
        </div>
        <div class="end">
          {{ awardData.end_date ? getDatetime(awardData.end_date) : '-' }}
        </div>
      </div>
      <div
        v-if="awardData.method_id === 3"
        class="end-block"
      >
        <div class="award-name">
          開獎時間
        </div>
        <div class="end">
          {{ awardData.lottery_at ? getDatetime(awardData.lottery_at) : '-' }}
        </div>
      </div>
      <div class="note-block">
        <div class="award-name">
          注意事項
        </div>
        <div class="note">
          {{ awardData.memo }}
        </div>
      </div>
    </div>
    <div
      v-if="showBtn"
      class="use-btn"
    >
      <Btn
        btn-text="使用"
        @click="handleUse"
      />
    </div>
    <NoticeDialog
      :is-open.sync="isOpenNotice"
      :notice="notice"
      :count="count"
      @confirm="handleConfirm"
    />
    <LotteryProcessDialog
      :is-open.sync="isOpenLotteryProcess"
    />
    <OfflineDialog
      :is-open.sync="isOpenOfflineDialog"
      :notice="notice"
      :user-point="userData.points"
      :need-point="awardData.point"
      @confirm="handleOfflineConfirm"
    />
    <ExchangeDialogConfirm
      :is-open.sync="isOpenDialog"
      :title="exchangeDialogConfirmTitle"
      :data="awardData"
      :win="winAward"
    />
  </div>
</template>

<script>
// import api from '@/utils/api'
import _ from 'lodash'
import api from '@/utils/api'
import helpers from '@/utils/helpers'
import { mapGetters } from 'vuex'
import dayjs from 'dayjs'
import authMixins from '@/mixins/auth'
import NoticeDialog from '@/components/global/NoticeDialog'
import LotteryProcessDialog from '@/components/global/LotteryProcessDialog'
import ExchangeDialogConfirm from '@/components/point/ExchangeDialogConfirm'

export default {
  components: { NoticeDialog, LotteryProcessDialog, ExchangeDialogConfirm },
  mixins: [authMixins],
  data() {
    return {
      isOpenNotice: false,
      isOpenDialog: false,
      isOpenOfflineDialog: false,
      isOpenLotteryProcess: false,
      exchangeDialogConfirmTitle: '',
      count: 0,
      winAward: ''
    }
  },
  computed: {
    ...mapGetters({
      userData: 'user/data',
      awardData: 'award/data'
    }),
    awardId() {
      return this.$route.params.no
    },
    // awardMetod() {
    //   return this.$route.query.method_id
    // },
    showBtn() {
      if (this.isEnd) return false
      // if (this.isEnd && this.isOffline) {
      //   return false
      // }
      // if (this.isEnd && !this.isOffline) {
      //   return true
      // }
      return true
    },
    isEnd() {
      return !dayjs().isBefore(this.awardData.end_date)
    },
    isOffline() {
      return this.awardData.method_id === 3
    },
    notice() {
      const user_points = _.get(this.userData, 'points', 0)
      if (user_points < this.awardData.point) {
        return '您的點數不足'
      }
      if (user_points > this.awardData.point && (this.awardData.method_id === 1 || this.awardData.method_id === 2)) {
        return '請確認是否使用點數'
      }
      if (user_points > this.awardData.point && this.isOffline && this.count) {
        return `您獲得${this.count}次抽獎資格`
      }
      if (user_points > this.awardData.point && this.isOffline) {
        return '選擇抽獎次數'
      }
      return ''
    }
  },
  async mounted() {
    await this.fetchAward()
  },
  methods: {
    async fetchAward() {
      this.$loading.start()
      await this.$store.dispatch('award/fetchData', { id: this.awardId })
      this.$loading.finish()
    },
    handleUse() {
      if (!this.isOffline) {
        this.isOpenNotice = true
        return
      }
      this.isOpenOfflineDialog = true
    },
    async handleConfirm() {
      if (this.notice === '您的點數不足') {
        return
      }
      if (this.notice === '請確認是否使用點數') {
        this.showLotteryProcess()
        setTimeout(() => {
          this.handleExchange()
        }, 1000)
      }
      if (this.notice === `您獲得${this.count}次抽獎資格`) {
        return
      }
    },
    showLotteryProcess() {
      this.isOpenLotteryProcess = true
    },
    closeLotteryProcess() {
      this.isOpenLotteryProcess = false
    },
    async handleExchange() {
      try {
        const formData = {
          award_id: this.awardId
        }
        const response = await api('EXCHANGE_AWARD', { data: formData })
        const { status, message } = response.data
        this.closeLotteryProcess()
        if (message === 'SORRY_YOU_MISS_THE_GAME') {
          this.isOpenDialog = true
          this.exchangeDialogConfirmTitle = '您未中獎'
          return
        }

        if (!status) {
          this.$toast.open({
            message: this.$error.getMessage(message),
            type: 'error',
            duration: 5000
          })
          return
        }

        this.isOpenDialog = true
        this.exchangeDialogConfirmTitle = ''
        this.winAward = _.get(response.data, 'data.0.award_name', '')

        try {
          this.$store.dispatch('user/fetchData')
        } catch (error) {
          console.log('error', error)
        }
      } catch (error) {
        console.log('error', error)
      }
    },
    async handleOfflineConfirm(count) {
      try {
        const data = {
          count,
          award_id: this.awardId
        }
        const response = await api('EXCHANGE_AWARD', { data })
        const { status, message } = response.data
        if (!status) {
          return this.$toast.open({
            message: this.$error.getMessage(message),
            type: 'error',
            duration: 5000
          })
        }

        try {
          this.$store.dispatch('user/fetchData')
        } catch (error) {
          console.log('error', error)
        }
        this.count = count
        this.isOpenNotice = true
        this.isOpenOfflineDialog = false
      } catch (error) {
        console.log('error', error)
      }
    },
    getImage(award) {
      const image_url = _.get(award, 'photos.0.url', '')
      if (!image_url) return require('@/assets/image/icon-gift.svg')
      return helpers.getUrl(image_url)
    },
    getDatetime(time) {
      return helpers.getDatetime(time)
    }
  }
}
</script>

<style lang="scss" scoped>
.detail {
  .title-wrapper {
    margin: 20px 0;
    display: flex;
    flex-direction: row;
    width: 100%;
  }
  .image-wrapper {
    margin-left: 10px;
    margin-right: 30px;
    width: 26%;

    img {
      object-fit: contain;
      width: 100%;
      height: 100%;
    }
  }
  .title-block {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
  }
  .type {
    width: 50px;
    height: 20px;
    border-radius: 6px;

    display: flex;
    justify-content: center;

    font-size: 14px;
    font-weight: bold;
    color: #ffffff;
    text-align: center;

  }

  .exchange {
    background-color: #32A9DD;
  }
  .online {
    background-color: #F29806;
  }
  .offline {
    background-color: #FD5387;
  }
  .award-name, .point {
    font-size: 20px;
    font-weight: bold;
  }
  .point-wrapper {
    margin: 4px 0;
    display: flex;
    flex-direction: row;
  }
  .point-icon {
    flex: 0 0 auto;
  }
  .point {
    margin-left: 8px;
  }
  .detail-block {
    margin-top: 50px;
    padding: 0 10px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  .end-block {
    margin-bottom: 30px;
  }
  .note-block, .end-block {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  .note, .end {
    width: 100%;
    margin-top: 4px;
    font-size: 16px;
    text-align: start;
  }
  .note {
    overflow: auto;
    white-space: pre-line;
  }
  .use-btn {
    position: fixed;
    bottom: 10px;
    left: 10px;
    right: 10px;
  }
}
</style>
