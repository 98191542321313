<template>
  <div class="line-redirect-page">
    <div class="box">
      <div class="title">
        Redirecting ...
      </div>
      <Circle4 />
      <router-link to="/ms-web/login">
        <v-btn
          class="link"
          type="primary"
        >
          Back to login page
        </v-btn>
      </router-link>
    </div>
  </div>
</template>

<script>
import _ from 'lodash'
import liff from '@line/liff'
import { mapActions } from 'vuex'
import { Circle4 } from 'vue-loading-spinner'

// import { setSerial } from '@/utils/api/methods'
import authMixins from '@/mixins/auth'
// import helpers from '@/utils/helpers'
import api from '@/utils/api'
import { removeJwtToken, setJwtToken } from '@/utils/api/methods'

export default {
  components: { Circle4 },
  mixins: [authMixins],
  data() {
    return {
      lineLoginFailure: false,
      facebookLoginFailure: false,
      provider: 'web',
      bundle_id: ''
    }
  },
  computed: {
    token() {
      return this.$route.query.token
    }
  },
  watch: {
    lineLoginFailure() {
      this.checkLoginFailure()
    },
    facebookLoginFailure() {
      this.checkLoginFailure()
    }
  },
  async mounted() {
    this.$root.loading = true
    removeJwtToken()

    // const token = getJwtToken()
    // if (token && !this.isLogin) {
    //   try {
    //     await this.$store.dispatch('user/fetchData')
    //   } catch (error) {
    //     console.log('error', error)
    //   }
    // }

    // if (this.isLogin) {
    //   return this.$router.push('/ms-web/member-center')
    // }

    // const userId = 'U51bc3795dada4ad9797c93e19d2ed24e'
    // this.setAuthDataByKey({ key: 'provider', value: 'line' })
    // this.setAuthDataByKey({ key: 'bundle_id', value: userId })

    // await this.handleShopperTokenLogin('line', userId)
    // const psid = '123'
    // this.setAuthDataByKey({ key: 'provider', value: 'facebook' })
    // this.setAuthDataByKey({ key: 'bundle_id', value: psid })
    // await this.handleShopperTokenLogin('facebook', psid)

    if (this.isWeb) {
      // Init line
      const _this = this
      await liff.init({
        liffId: this.liffId
      }).then(() => {
        liff.getProfile().then(async(profile) => {
          const { userId } = profile
          console.log('userId', userId)
          if (!userId) return

          _this.provider = 'line'
          _this.bundle_id = userId

          _this.setAuthDataByKey({ key: 'provider', value: 'line' })
          _this.setAuthDataByKey({ key: 'bundle_id', value: userId })
          _this.setAuthDataByKey({ key: 'lineData', value: profile })

          await _this.handleShopperTokenLogin('line', userId)
        }).catch((e) => {
          console.log('line error', e)
          this.lineLoginFailure = true
        })
      }).catch((e) => {
        console.log('error', e)
        this.lineLoginFailure = true
      })

      // Init facebook
      const psid = _.get(this.$route, 'query.psid', null)
      if (psid) {
        this.provider = 'facebook'
        this.bundle_id = psid

        this.setAuthDataByKey({ key: 'provider', value: 'facebook' })
        this.setAuthDataByKey({ key: 'bundle_id', value: psid })
        await this.handleShopperTokenLogin('facebook', psid)
      } else {
        this.facebookLoginFailure = true
      }
    }

    this.$root.loading = false
  },
  methods: {
    ...mapActions({
      setUserData: 'user/setData'
    }),
    checkLoginFailure() {
      if (!this.lineLoginFailure || !this.facebookLoginFailure) return

      this.$toast.open({
        message: '無法使用社群登入，請使用管理員帳號密碼',
        type: 'warning',
        duration: 5000
      })
      this.$router.push(`/shopper/login?provider=${this.provider}&bundle_id=${this.bundle_id}`)
    },
    handleRedirect() {
      this.$router.push({ name: 'MemberCenter' })
    },
    handleShopperTokenLogin(provider, bundle_id) {
      return new Promise((resolve, reject) => {
        if (this.isLogin) {
          resolve()
          return
        }

        const data = {
          type: provider,
          bundle_id
        }
        api('SHOPPER_LOGIN_WITH_TOKEN', { data })
          .then((response) => {
            const { status, message, data } = response.data

            if (message && !status) {
              this.lineLoginFailure = true
              if (message === 'USER_DOSE_NOT_EXIST') {
                this.$toast.open({
                  message: '管理員帳戶並未進行社群綁定，請輸入帳號密碼進行登入並綁定',
                  type: 'warning',
                  duration: 0
                })
              } else {
                this.$toast.open({
                  message: this.$error.getMessage(message),
                  type: 'error',
                  duration: 0
                })
              }

              this.$router.push(`/shopper/login?provider=${this.provider}&bundle_id=${this.bundle_id}`)
              resolve()
              return
            }

            setJwtToken(data.access_token)
            this.$store.dispatch('user/setData', data.user)
            this.$toast.open({
              message: '登入成功',
              type: 'success'
            })
            this.$router.push('/ms-web/point-award')
            resolve()
          })
          .catch(() => {
            this.lineLoginFailure = true
          })
      })
    }
  }
}
</script>

<style lang="scss" scoped>
$bg:#2d3a4b;

.line-redirect-page {
  min-height: 100vh;
  width: 100%;
  background-color: $bg;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;

  .title {
    margin-bottom: 12px;
    font-weight: bold;
  }

  .box {
    margin: 0 auto;
    width: 520px;
    max-width: 100%;
    background-color: #fff;
    border-radius: 14px;
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .link {
    margin-top: 20px;
  }
}
</style>
