<template>
  <div id="consumption-detail">
    <div class="container">
      <PageTitle back="/ms-web/consumption-records">
        消費紀錄明細
      </PageTitle>
      <div class="record-block">
        <div class="record">
          訂單編號：{{ consumptionData.serial }}
        </div>
        <div class="record">
          購買門市：{{ consumptionData.store_name }}
        </div>
        <div class="record">
          購買日期：{{ consumptionData.purchaseDate }}
        </div>
        <!-- <div class="record" @click="handleWriteSurvey">
          填寫問卷(開發用)
        </div> -->
        <div
          class="record record-state"
          :class="handleStatusClass(consumptionData.status)"
        >
          狀態： <p>{{ getConsumptionStatus(consumptionData.status) }}</p>
        </div>
        <div
          v-if="consumptionData.status === 3"
          class="record record-state"
        >
          未通過原因： <p>{{ reason }}</p>
        </div>
      </div>
      <div class="detail-block">
        <div class="item">
          品項
        </div>
        <div
          v-for="(item, index) in items"
          :key="index"
        >
          <div class="details">
            {{ getDetail(item) }}
          </div>
          <!-- <div class="details">
            {{ consumptionData.commodity }}
          </div> -->
        </div>
      </div>
      <div
        v-if="consumptionData.status !== 1"
        class="invoice-block"
      >
        <div class="invoice-label">
          發票照片
        </div>
        <PreviewBlock
          :images="images"
        />
      </div>
      <Btn
        v-if="consumptionData.status === 1"
        class="bottom-btn"
        btn-text="上傳發票照片"
        @click="handleChangePage"
      />
      <Btn
        v-if="consumptionData.status === 3"
        class="bottom-btn"
        btn-text="重新上傳發票"
        @click="handleChangePage"
      />
    </div>
  </div>
</template>

<script>
import _ from 'lodash'
import { mapGetters } from 'vuex'
import api from '@/utils/api'
import ConsumptionMixins from '@/mixins/consumption'

import PreviewBlock from '@/components/consumption/PreviewBlock'

export default {
  components: { PreviewBlock },
  mixins: [ConsumptionMixins],
  computed: {
    ...mapGetters({
      consumptionData: 'consumption/data'
    }),
    items() {
      return this.consumptionData.items || []
    },
    currentReview() {
      const { reviews = [] } = this.consumptionData
      return _.first(reviews) || {}
    },
    reason() {
      return _.get(this.currentReview, 'reason', '')
    },
    images() {
      return _.get(this.currentReview, 'photos', [])
    }
  },
  mounted() {
    this.fetchData()
  },
  methods: {
    handleWriteSurvey() {
      const { no: id } = this.$route.params
      this.$router.push(`/ms-web/consumer-questionnaire/${id}`)
    },
    async fetchData() {
      const { no: id } = this.$route.params
      try {
        const response = await api('GET_ORDER', { id })
        const { status, data, message } = response.data
        if (status) {
          const consumptionData = _.get(data, '0', {})
          this.$store.dispatch('consumption/setData', consumptionData)

          return
        }

        this.$toast.open({
          message: this.$error.getMessage(message),
          type: 'error'
        })
        this.$router.push('/ms-web/consumption-records')
      } catch (error) {
        console.log('error', error)
      }
    },
    handleBack() {
      this.$router.push('/ms-web/consumption-records')
    },
    handleStatusClass(status) {
      if ([1, 2].includes(status)) {
        return 'is-red'
      }
    },
    handleChangePage() {
      const { no } = this.$route.params
      this.$router.push(`/ms-web/consumption-records/${no}/upload`)
    },
    // getFailedReason(reason) {
    //   switch(reason) {
    //   case 'blurred':
    //     return '照片模糊';
    //   case 'incompatible':
    //     return '發票上商品品項不符';
    //   default:
    //     return ''
    //   }
    // },
    getDetail(item) {
      const arr = [item.color, item.size, item.sdd, item.memory].filter(item => !!item).join('/')
      return `${item.type_name} ${item.name} (${arr})`
    }
  }
}
</script>

<style lang="scss" scoped>
#consumption-detail {
  .container {
    padding-bottom: 120px;
  }
  .left-icon {
    width: 10px;
  }
  .record-block, .detail-block {
    text-align: left;
    padding-bottom: 20px;
  }
  .record {
    font-size: 14px;
    color: $text-1;
    &.is-red {
      p {
        color: red;
      }
    }
  }
  .record-state {
    p {
      display: inline;
    }
  }
  .item {
    font-size: 14px;
    font-weight: bold;
    border-bottom: 2px solid $text-1;
    padding-bottom: 5px;
  }
  .details {
    font-size: 14px;
    font-weight: normal;
    padding: 8px 0;
    border-bottom: 2px solid $background-light;
  }
  .invoice-label {
    font-size: 16px;
    color: $text-1;
    text-align: left;
    padding-bottom: 8px;
  }
  .invoice-image {
    width: 329px;
    height: 250px;
    border-radius: 15px;
    border: 1px solid #d0cfcf;
  }
}
</style>
