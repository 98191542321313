<template>
  <div id="member-center">
    <div class="container">
      <div class="page-title">
        會員中心
      </div>
      <div class="btn-wrapper">
        <div class="card-section">
          <MemberCard />
          <MileageSection />
        </div>
        <div class="btn-block">
          <ShoppingCameraBtn @click="handleOpenQRCodeReader" />
          <div
            class="member-btn"
            @click="handleChangePage()"
          >
            會員資料
          </div>
          <div
            class="member-btn"
            @click="handleRules"
          >
            集點規則
          </div>
          <div
            class="member-btn"
            @click="handleExplanation"
          >
            會員機制說明
          </div>
          <NoticeDialog
            :notice="notice"
            :is-open.sync="openNoticeDialog"
          />
        </div>
      </div>
      <QRCodeReaderDialog
        v-if="openQRCodeReader"
        :is-open="openQRCodeReader"
        @close="handleCloseQRCodeReader"
      />
      <MemberBtn
        class="big-btn"
        :is-active="value"
      />
      <Dialog
        :is-open.sync="openDialog"
        :confirmation-text="confirmationText"
        @confirm="handleConfirm"
      />
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'

import authMixins from '@/mixins/auth'
import api from '@/utils/api'
import { getSerial, removeSerial } from '@/utils/api/methods'

import MemberCard from '@/components/member-center/MemberCard'
import MileageSection from '@/components/member-center/MileageSection'
import ShoppingCameraBtn from '@/components/member-center/ShoppingCameraBtn'
import QRCodeReaderDialog from '@/components/member-center/QRCodeReaderDialog'
import MemberBtn from '@/components/global/MemberBtn'

export default {
  components: {
    MemberCard,
    ShoppingCameraBtn,
    MemberBtn,
    QRCodeReaderDialog,
    MileageSection
  },
  mixins: [authMixins],
  data() {
    return {
      value: 0,
      openQRCodeReader: false,
      notice: '',
      openNoticeDialog: false,
      confirmationText: '',
      openDialog: false,
      dialogConfirmCallback: () => {}
    }
  },
  mounted() {
    this.$store.dispatch('user/fetchData')
    this.$nextTick(() => {
      this.checkSerial()
      this.$root.loading = false
    })
  },
  methods: {
    ...mapActions({
      fetchData: 'user/fetchData'
    }),
    handleChangePage() {
      this.$router.push('/ms-web/member-info')
    },
    handleOpenQRCodeReader() {
      this.openQRCodeReader = true
    },
    handleCloseQRCodeReader() {
      this.openQRCodeReader = false
    },
    handleRules() {
      this.notice = '集點規則文案'
      this.openNoticeDialog = true
    },
    handleExplanation() {
      this.notice = '會員機制說明文案'
      this.openNoticeDialog = true
    },
    checkSerial() {
      if (this.isManager) return
      const serial = getSerial()
      if (!serial) return
      const data = {
        id: serial,
        force: true
      }
      api('BIND_ORDER', { data }).then((response) => {
        const { status, message } = response.data
        if (!status) {
          this.$toast.open({
            message: this.$error.getMessage(message),
            type: 'error',
            duration: 5000
          })
          this.$router.push({ name: 'Login' })
          return
        }

        this.$store.dispatch('consumption/fetchPendingList')
        this.confirmationText = '訂單已匯入，請問是否要上傳發票？'
        this.openDialog = true
        this.dialogConfirmCallback = () => {
          this.$router.push(`/ms-web/consumer-questionnaire/${serial}`)
        }
      }).finally(() => {
        removeSerial()
      })
    },
    handleConfirm() {
      this.dialogConfirmCallback()
    }
  }
}
</script>

<style lang="scss" scoped>
#member-center {
  .member-btn {
    background-color: $background-light;
    margin-top: 18px;
    height: 32px;
    line-height: 32px;
    border-radius: 3px;
    font-size: 16px;
    font-weight: bold;
    cursor: pointer;
  }
  @media screen and (min-width: 767px) {
    .card-section {
      width: calc(100% / 2);
    }

    .container {
      text-align: center;
    }
    .page-title {
      padding-bottom: 40px;
    }
    .btn-wrapper {
      display: flex;
      flex-direction: row;
      justify-content: space-around;
      align-items: center;
    }
    .btn-block {
      width: 45%;
    }
    ::v-deep .shopping-camera-btn {
      padding-top: 0;
    }
    .big-btn {
      right: 70px;
      left: 70px;
    }
  }
}
</style>

