<template>
  <div class="preview-block">
    <div
      v-for="(image, index) in images"
      :key="index"
      class="invoice-image"
    >
      <img :src="getImageUrl(image)">
    </div>
  </div>
</template>

<script>
import helpers from '@/utils/helpers'

export default {
  props: {
    images: {
      type: Array,
      default: () => []
    }
  },
  methods: {
    getImageUrl(image) {
      if (image.url) {
        return helpers.getUrl(image.url)
      }

      return image
    }
  }
}
</script>

<style lang="scss" scoped>
.preview-block {
  border: 1px solid $background-light;
  border-radius: 12px;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  img {
    width: 100%;
    height: 180px;
    padding: 2px 2px;
    object-fit: contain;
  }

  .invoice-image {
    display:flex;
    align-items: center;
    justify-content: center;
    margin: 4px;
    width: calc(50% - (4px * 2));
    background-color: $background-light;
    border-radius: 15px;
  }
}
</style>
