<template>
  <div class="dialog-block">
    <v-dialog
      v-model="isActive"
      class="exchange-dialog"
      max-width="250"
    >
      <v-card class="dialog-card">
        <div v-if="title" style="white-space: pre-line;">
          <v-card-text>
            {{ title }}
          </v-card-text>
          <v-spacer />
          <v-btn
            color="primary"
            outlined
            :width="180"
            @click="closeDialog"
          >
            確認
          </v-btn>
        </div>
        <div v-else>
          <v-card-text v-if="data.method_id === 1">
            {{ data.name }}<br>兌換成功
          </v-card-text>
          <v-card-text v-if="data.method_id === 2">
            恭喜抽中<br>{{ win }}
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn
              color="primary"
              outlined
              :width="180"
              @click="handleExchangeSuccess"
            >
              查看優惠券
            </v-btn>
          </v-card-actions>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  props: {
    isOpen: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: ''
    },
    data: {
      type: Object,
      default: () => {}
    },
    win: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      isActive: this.isOpen
    }
  },
  watch: {
    isOpen() {
      this.isActive = this.isOpen
    },
    isActive() {
      this.$emit('update:isOpen', this.isActive)
    }
  },
  methods: {
    handleExchangeSuccess() {
      this.closeDialog()
      this.$router.push('/ms-web/coupon')
    },
    closeDialog() {
      this.isActive = false
    }
  }
}
</script>
