<template>
  <div class="gift-card-wrapper">
    <div v-if="data.length === 0">
      <span class="empty-award">無獎項</span>
    </div>
    <v-row>
      <v-col
        v-for="(award, index) in data"
        :key="index"
        cols="6"
        @click="handleToAwardDetail(award)"
      >
        <div class="gift-card-block">
          <div
            v-if="isExpired"
            class="mask"
          />
          <div class="type-block">
            <div
              v-if="award.type === 'exchange'"
              class="type exchange"
            >
              兌換
            </div>
            <div
              v-if="award.type === 'online'"
              class="type online"
            >
              立即抽
            </div>
            <div
              v-if="award.type === 'offline'"
              class="type offline"
            >
              幸運抽
            </div>
          </div>
          <div class="awarid-image">
            <div class="image-wrapper">
              <div class="image-container">
                <img
                  class="gift-img"
                  :src="award.image_url"
                >
              </div>
            </div>
          </div>
          <div class="award-wrapper">
            <div class="award-name">
              {{ award.name }}
            </div>
            <div class="point-wrapper">
              <img src="@/assets/image/point-icon-new.svg">
              <div class="point">
                {{ award.need_point }}
              </div>
            </div>
            <div class="end">
              活動截止時間：{{ award.activity_end }}
            </div>
            <div v-if="award.type === 'offline'" class="end">
              本人獲得抽獎次數：{{ handleOfflineLotteryCount(award) }}
            </div>
          </div>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import _ from 'lodash'
export default {
  props: {
    data: {
      type: Array,
      default: () => []
    },
    isExpired: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    handleOfflineLotteryCount(award) {
      const not_upload = _.get(award, 'user_records', [])
      return not_upload.length
    },
    handleToAwardDetail(award) {
      // 過期的話，點擊無效
      if (this.isExpired) return
      this.$router.push(`/ms-web/point-award/${award.id}`)
    }
  }
}
</script>

<style lang="scss" scoped>
.gift-card-wrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  &::v-deep .col {
    padding: 8px;
  }

  .gift-card-block {
    position: relative;
    border-radius: 6px;
    box-shadow: 2px 2px 8px rgba(0,0,0,0.2);
    background-color: #fff;
    height: 100%;

    .mask {
      position: absolute;
      width: 100%;
      border-radius: 6px;
      top: 0;
      bottom: 0;
      background-color: rgba(0, 0, 0, 0.25);
      z-index: 1;
    }
  }

  .type {
    width: 46px;
    height: 20px;
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    font-weight: bold;
    color: #ffffff;
    text-align: center;
  }
  .type-block {
    padding: 10px;
  }

  .exchange {
    background-color: #32A9DD;
  }
  .online {
    background-color: #F29806;
  }
  .offline {
    background-color: #FD5387;
  }

  .awarid-image {
    padding: 0 16px;
  }
  .image-wrapper {
    position: relative;
    width: 100%;
    padding-bottom: 100%;
    display: flex;
    justify-content: center;

    .image-container {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
    }

    .gift-img {
      object-fit: contain;
      width: 100%;
      height: 100%;
    }
  }
  .award-wrapper {
    padding: 10px 20px;
  }
  .award-name, .point {
    font-size: 16px;
    font-weight: bold;
  }
  .point-wrapper {
    margin: 4px 0;
    display: flex;
    flex-direction: row;
  }
  .point {
    margin-left: 8px;
  }
  .end {
    font-size: 12px;
  }
  .empty-award {
    color: #888;
  }
}
</style>
