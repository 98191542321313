import Vue from 'vue'
import Vuetify from 'vuetify'
// import colors from 'vuetify/lib/util/colors'

import 'vuetify/dist/vuetify.min.css'
import '@mdi/font/css/materialdesignicons.css'
import 'material-design-icons-iconfont/dist/material-design-icons.css'

Vue.use(Vuetify)

const opts = {
  theme: {
    themes: {
      light: {
        primary: '#1550d1',
        secondary: '#2e424f',
        accent: '#9fbdeb',
        error: '#b71c1c'
      }
    }
  }
}

export default new Vuetify(opts)
