<template>
  <div id="qr-code-reader">
    <v-row justify="center">
      <v-dialog
        v-model="isActive"
        max-width="290"
        max-height="290"
      >
        <img
          class="close-icon"
          :src="require('@/assets/image/icon-close-w.svg')"
          @click="handleClose"
        >
        <v-card class="dialog-card">
          <qrcode-stream
            :camera="camera"
            @decode="handletoQuestionnaire"
          >
            <button
              calss="switch-icon"
              @click="switchCamera"
            >
              <img :src="require('@/assets/image/icon-switch.svg')">
            </button>
          </qrcode-stream>
        </v-card>
      </v-dialog>
    </v-row>
  </div>
</template>

<script>
// import _ from 'lodash'
import { QrcodeStream } from 'vue-qrcode-reader'

import api from '@/utils/api'
import helpers from '@/utils/helpers'
// import { qrcodeRedirectUrl } from '@/utils/api/variable'

export default {
  components: {
    QrcodeStream
  },
  model: {
    event: ['close']
  },
  props: {
    isOpen: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isActive: this.isOpen,
      // camera: 'front'
      camera: 'rear'
    }
  },
  watch: {
    isOpen() {
      this.isActive = this.isOpen
    },
    isActive() {
      this.$emit('update:isOpen', this.isActive)
    }
  },
  methods: {
    async handletoQuestionnaire(decodeString) {
      // console.log('handletoQuestionnaire')
      // const data = '{"id":25,"qrcode_vaild_at":"2021-10-24 11:34:38"}'
      // const parseData = JSON.parse(data)
      // const decodeString2 = 'https://liff.line.me/1655444779-zBjnJVY1?id=105'
      // const parseData = JSON.parse(decodeString)
      // console.log('decodeString', decodeString)
      // const refexp = `/id=/gm`
      // console.log('refexp', refexp)
      // const stringArray = decodeString.split(refexp)
      // const id = _.get(stringArray, 1, '')
      const id = helpers.getSerialId(decodeString)
      if (!id) {
        return this.$toast.open({
          message: 'Qrcode 有誤',
          type: 'error',
          duration: 5000
        })
      }
      try {
        // const response = await this.$api('GET_ORDER', { id })
        // const { status, data, message } = response.data
        // console.log('response.data', response.data)
        // if (!status) {
        //   return this.$toast.open({
        //     message: this.$error.getMessage(message),
        //     type: 'error',
        //     duration: 5000
        //   })
        // }

        // const orderData = _.get(data, '0', {})
        // const orderId = orderData.id
        // if (!orderId) {
        //   return this.$toast.open({
        //     message: '未取得訂單編號',
        //     type: 'error',
        //     duration: 5000
        //   })
        // }

        // await this.$store.dispatch('order/setData', orderData)
        // TODO: change to url
        const bindResponse = await api('BIND_ORDER', { data: { id }})
        const { status: bindStatus, message: bindMessage } = bindResponse.data
        if (!bindStatus) {
          return this.$toast.open({
            message: this.$error.getMessage(bindMessage),
            type: 'error',
            duration: 5000
          })
        }

        this.$router.push(`/ms-web/consumer-questionnaire/${id}`)
      } catch (error) {
        console.log('error', error)
      }
    },
    switchCamera() {
      if (this.camera === 'front') {
        this.camera = 'rear'
        return
      }
      if (this.camera === 'rear') {
        this.camera = 'front'
        return
      }
    },
    handleClose() {
      this.$emit('close')
    }
  }
}
</script>

