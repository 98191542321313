<template>
  <div class="notice-dialog">
    <v-dialog
      v-model="isActive"
      max-width="250"
    >
      <v-form @submit.prevent="handleSubmit">
        <v-card class="dialog-card">
          <v-card-text>
            {{ notice }}
          </v-card-text>
          <VInput
            reqiured
            type="number"
            :value.sync="form.count"
          />
          <div class="piont-count">
            <div class="point">
              可用點數：{{ userPoint }}
            </div>
            <div class="point">
              使用點數：{{ getNeedPointTotal() }}
            </div>
          </div>
          <v-card-actions>
            <v-spacer />
            <v-btn
              color="#757575"
              outlined
              @click="handleCancel"
            >
              取消
            </v-btn>
            <v-btn
              color="primary"
              outlined
              :disabled="disabledBtn"
              @click="handleConfirm"
            >
              確認
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
  </div>
</template>

<script>
export default {
  model: {
    event: ['confirm']
  },
  props: {
    isOpen: {
      type: Boolean,
      default: false
    },
    notice: {
      type: String,
      default: ''
    },
    userPoint: {
      type: Number,
      default: 0
    },
    needPoint: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      isActive: this.isOpen,
      needPointTotal: 0,
      form: {
        count: 1
      }
    }
  },
  computed: {
    disabledBtn() {
      return this.needPoint * this.form.count > this.userPoint
    }
  },
  watch: {
    isOpen() {
      this.isActive = this.isOpen
    },
    isActive() {
      this.$emit('update:isOpen', this.isActive)
    }
  },
  methods: {
    handleConfirm() {
      // this.isActive = false
      this.$emit('confirm', this.form.count)
    },
    getNeedPointTotal() {
      return this.needPoint * this.form.count
    },
    handleSubmit() {
      this.handleConfirm()
    },
    handleCancel() {
      this.isActive = false
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep .dialog-card {
  padding: 40px 40px 20px 40px !important;
}
.piont-count {
  margin: 10px 0;
  text-align: left;
}
.point {
  color: #707070;
}
</style>
