import _ from 'lodash'
import dayjs from 'dayjs'
import { backendBaseUrl } from '@/utils/api/variable'

export default {
  getUrl(path) {
    return `${backendBaseUrl}${path}`
  },
  getDatetime(datetime) {
    return dayjs(datetime).format('YYYY.MM.DD HH:mm:ss')
  },
  getSerialId(url) {
    const refexp = /id=/gm
    const stringArray = url.split(refexp)
    const id = _.get(stringArray, 1, '')
    return id
  }
}
