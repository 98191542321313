import _ from 'lodash'
import Swal from 'sweetalert2'
import liff from '@line/liff'
import { mapGetters, mapActions } from 'vuex'

import api from '@/utils/api'
import { addFriendShipmentUrl } from '@/utils/api/variable'
import { getJwtToken, removeJwtToken, setJwtToken } from '@/utils/api/methods'

export default {
  computed: {
    ...mapGetters({
      isManager: 'user/isManager',
      userData: 'user/data',
      authData: 'auth/data'
    }),
    liffId() {
      // console.log('liffId', process.env.VUE_APP_LIFF_ID)
      return '1655568204-aQdEZb1R'
    },
    facebookAppId() {
      return process.env.VUE_APP_FACEBOOK_APP_ID
    },
    isWeb() {
      return this.authData.provider === 'web'
    },
    isLine() {
      return this.authData.provider === 'line'
    },
    isFacebook() {
      return this.authData.provider === 'facebook'
    },
    isLogin() {
      return !!this.userData.created_at
    }
  },
  methods: {
    ...mapActions({
      setAuthDataByKey: 'auth/setDataByKey'
    }),
    getJwtToken() {
      return getJwtToken()
    },
    removeJwtToken() {
      removeJwtToken()
    },
    socialLogin(provider, bundle_id) {
      if (this.isLogin) {
        return
      }

      const data = {
        type: provider,
        bundle_id
      }
      api('SOCIAL_LOGIN', { data }).then((response) => {
        const { status, message, data } = response.data

        if (message && !status) {
          return
        }

        setJwtToken(data.access_token)
        this.$store.dispatch('user/setData', data.user)
        this.$toast.open({
          message: '登入成功',
          type: 'success'
        })
        this.$router.push('/ms-web/member-center')
      })
    },
    async initLine() {
      return new Promise((resolve, reject) => {
        liff.init({
          liffId: this.liffId
        }).then(() => {
          this.handleLineInitSuccess()
          resolve()
        }).catch((err) => {
          this.handleLineInitError()
          reject(err)
        })
      })
    },
    handleLineInitSuccess(response) {
      liff.getFriendship().then((data) => {
        console.log('getFriendship', data)
        if (data.friendFlag) {
          return this.getProfile()
        }

        Swal.fire({
          icon: 'warning',
          title: '請先加入LINE官方帳號好友!'
        }).then(() => {
          location.href = addFriendShipmentUrl
        })
      })
    },
    // initializeLiff(myLiffId) {
    //   liff
    //     .init({
    //       liffId: myLiffId
    //     })
    //     .then(() => {
    //       if (!liff.isLoggedIn()) {
    //         liff.login()
    //       } else {
    //         const idToken = liff.getIDToken()
    //         liff.getFriendship().then(data => {
    //           if (data.friendFlag) {
    //             liff.getProfile().then(function(profile) {
    //               const userId = profile.userId
    //               $('#user_token').val(userId)
    //             }).catch(function(error) {
    //               Swal.fire({
    //                 icon: 'error',
    //                 title: '取得資料失敗, 請稍後在試!!'
    //               })
    //             })
    //           } else {
    //             Swal.fire({
    //               icon: 'warning',
    //               title: '請先加入克菸LINE官方帳號好友!'
    //             }).then((result) => {
    //               location.href = 'https://lin.ee/spEGd4o'
    //             })
    //           }
    //         })
    //       }
    //     })
    //     .catch((err) => {
    //       Swal.fire({
    //         icon: 'error',
    //         title: '取得資料失敗, 請稍後在試!!'
    //       })
    //     })
    // },
    handleLineInitError(error) {
      console.log('handleLineInitError', error)
    },
    getProfile() {
      const _this = this
      liff.getProfile().then(async(profile) => {
        const { userId } = profile
        if (!userId) {
          return
        }

        _this.setAuthDataByKey({ key: 'provider', value: 'line' })
        _this.setAuthDataByKey({ key: 'bundle_id', value: userId })
        _this.setAuthDataByKey({ key: 'lineData', value: profile })

        const isCheck = await _this.checkStatement()
        if (!isCheck) return
        _this.socialLogin('line', userId)
      })
    },
    async initFacebook() {
      const psid = _.get(this.$route, 'query.psid', null)
      console.log('psid', psid)
      if (!psid) {
        return
      }

      this.setAuthDataByKey({ key: 'provider', value: 'facebook' })
      this.setAuthDataByKey({ key: 'bundle_id', value: psid })

      const isCheck = await this.checkStatement()
      if (!isCheck) return
      this.socialLogin('facebook', psid)
    },
    async checkStatement() {
      if (this.isWeb) {
        return true
      }

      try {
        const response = await api('GET_CHECK_PRIVACY', {
          data: {
            type: this.authData.provider,
            bundle_id: this.authData.bundle_id
          }
        })
        const { status } = response.data
        if (status) return true

        this.isOpenStatementDialog = true
        return false
        // this.$router.push('/ms-web/service-statement')
      } catch (error) {
        console.log('error', error)
      }

      return true
    }
  }
}
