const errorDictionary = {
  'USER_DOSE_NOT_EXIST': '使用者不存在',
  'USER_IS_EXISTING': '此信箱已註冊',
  'PASSWORD_IS_NOT_CORRECT': '密碼錯誤',
  'TOKEN_IS_INVALID': 'Token 已失效，請重新登入',
  'THE_VERIFICATION_LINK_IS_INCORRECT_OR_EXPIRED': '連結已失效，請取得新的連結',
  'ID_HAS_BEEN_USED': '此裝置已綁定其他使用者',
  'THE_MAIL_AND_ID_DOSE_NOT_MATCH': '此Email已綁定其他裝置',
  'INCORRECT_TYPE': '錯誤的類別',
  'QRCODE_IS_EXPIRED': 'Qrcode已過期',
  'THE_ORDER_HAS_BEEN_RIGISTED': '訂單已使用',
  'ORDER_NOT_EXIST': '此訂單不存在',
  'THE_ITEM_WAS_CHANGE_OVER': '獎項不足，無法兌換',
  'YOU_DO_NOT_HAVE_ENOUGH_POINTS': '紅利點數餘額不足',
  'EMAIL_NOT_VERIFY': 'email未驗證',
  'EMAIL_ERROR': 'Email不允許，請輸入正確的格式',
  'MAXIMUM_OF_TEN_PHOTOS': '圖片數量不可大於十張',
  'TOKEN_IS_EXPIRED': '請重新登入',
  'MANAGER_DOSE_NOT_EXIST': '使用者不存在，請重新登入',
  'QRCODE_IS_NOT_EXIST': 'Qrcode 序號有誤',
  'EMAIL_HAS_BEEN_USED_FOR_USER': '此Line帳號綁定的管理員帳號信箱，已備註冊成一般用戶，請刪除一般用戶帳號再進行管理員登入',
  'THE_ACCOUNT_DOES_NOT_ENABLE': '帳號已停用',
  'EMAIL_HAS_BENN_REGISTER_TO_SHOPPER': '此信箱已被使用',
  'MANAGER_CAN_NOT_BIND_RECORD': '店員帳號無法登入銷售紀錄'
}

export {
  errorDictionary
}
