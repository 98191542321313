import Cookies from 'js-cookie'

export const getJwtToken = () => Cookies.get('ms_access_token')
export const setJwtToken = (value) => Cookies.set('ms_access_token', value)
export const removeJwtToken = () => Cookies.remove('ms_access_token')

export const getSerial = () => Cookies.get('ms_serial')
export const setSerial = (serial) => Cookies.set('ms_serial', serial)
export const removeSerial = () => Cookies.remove('ms_serial')

export default {
  getJwtToken,
  removeJwtToken,
  setJwtToken,
  getSerial,
  setSerial,
  removeSerial
}
