export default {
  methods: {
    getConsumptionStatus(status) {
      switch (status) {
        case 1:
          return '待上傳發票'
        case 2:
          return '已上傳發票'
        case 3:
          return '未通過'
        case 4:
          return '已通過'
        case 5:
          return '已結束'
      }
    }
  }
}
