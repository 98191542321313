const state = () => ({
  data: {
    provider: 'web',
    bundle_id: null,
    facebookData: null,
    lineData: null
  }
})

const getters = {
  data(state) {
    return state.data
  }
}

const mutations = {
  setData(state, data) {
    state.data = data
  },
  setDataByKey(state, { key, value }) {
    state.data[key] = value
  },
  resetData(state) {
    state.data = {
      provider: 'web',
      bundle_id: null,
      facebookData: null,
      lineData: null
    }
  }
}

const actions = {
  setData({ commit }, data) {
    commit('setData', data)
  },
  setDataByKey({ commit }, { key, value }) {
    commit('setDataByKey', { key, value })
  },
  resetData({ commit }) {
    commit('resetData')
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
