<template>
  <div id="pwd-confirm">
    <div class="container">
      <div class="page-wrapper">
        <div class="notice">
          驗證失敗，連結已失效
        </div>
        <div class="btn-block">
          <Btn
            class="btn"
            :btn-text="toLineBtn"
          />
          <Btn :btn-text="toFBBtn" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Btn from '@/components/global/Btn'
export default {
  components: {
    Btn
  },
  data() {
    return {
      toLineBtn: '前往LINE官方帳號',
      toFBBtn: '前往Facebook'
    }
  }
}
</script>

<style lang="scss" scoped>
#pwd-confirm {
  min-height: 100vh;
  .container {
    display:  flex;
    align-items: center;
    justify-content:  center;
  }
  .page-wrapper {
    width: 100%;
    padding: 0 30px;
  }
  .notice {
    color: $text-1;
    font-size: 20px;
    padding-bottom: 70px;
  }
  .btn {
    padding-bottom: 20px;
  }
}
</style>
