<template>
  <div class="service-statement">
    <v-dialog
      v-model="isActive"
      persistent
      max-width="650"
    >
      <v-card class="dialog-card">
        <div class="statement-wrapper">
          <v-card-title class="statement-title">
            個人化服務聲明
          </v-card-title>
          <v-card-text class="statement-content">
            <h4>同意條款</h4>
            <div class="text">
              xxxxxx公司（以下簡稱「本公司」）係依據本使用聲明提供您瀏覽及使用本網站（xxx），包括但不限於本網站中之文字、圖片、訊息、影音、網頁、超鏈結、檔案、程式、搜尋服務、其它資料與服務等，在您瀏覽或使用本網站任何部分前，本公司希望您能詳細閱讀本網站使用聲明，當您連結或存取本網站任何一部分時，即表示您已詳閱本網站使用聲明之內容，且同意確實遵守，如您為限制行為能力人或無行為能力人，您應於您的父母或法定代理人瞭解並同意本網站使用聲明後，再行瀏覽或使用本網站。<br>
            </div>

            <h4>本網站權利歸屬</h4>
            <div class="text">
              本網站所使用之軟體或程式、網站上所有內容，包括但不限於著作、圖片、檔案、影像、錄音、資訊、資料、網站架構、網站畫面的安排或網頁設計等，均由本公司或其他權利人依法擁有其智慧財產權，且不限於商標權、專利權、著作權、營業秘密與專有技術等。除瀏覽本網站外，未經本公司或其他權利人事前書面同意，不得重製、下載、改作、編輯及超鏈結本網站之全部或一部，或將其傳送或散布予任何第三人。 您在合理使用範圍內，依著作權法第六十五條規定得重製、公開播送或公開傳輸。惟利用前應明示其出處，或取得本公司或其他權利人的同意後，方得刊載。
            </div>

            <h4>修改權</h4>
            <div class="text">
              本公司得隨時修改、變更、置換或停止本網站之全部或一部內容（包括本網站使用聲明），無須事前徵得您的同意。您於任何修改或變更後繼續使用本網站者，即視為您已閱讀、瞭解並同意接受該等修改或變更。 惡意使用之禁止您不得以木馬、病毒、惡意程式、後門程式、駭客入侵、阻斷式攻擊、上載資料或其它任何方式，修改、變更、增加或刪除本網站之全部或一部，及阻礙、延滯、干擾、損壞或盜用本公司任何硬體及軟體之使用、功能及運作。 您不得以任何方式利用或使用本網站，或本公司任何硬體或軟體之全部或一部，以自己或他人名義對第三人提供有償或無償之資料或服務。 您不得以任何方式利用或使用本網站，或本公司任何硬體或軟體之全部或一部，從事侵害他人權益、進行任何網路犯罪、違反中華民國法令、網路訊息經過當地國法令、國際慣例或公約之行為。 您同意應以善良管理人之注意義務使用本網站，並確保個人資料之機密性、正確性及完整性，如遭第三人竊取、破解、竄改或盜用等，所致任何損害者，您應自負一切責任。
            </div>

            <h4>資料蒐集</h4>
            <div class="text">
              對於您瀏覽或使用本網站所生之相關資料，包括但不限於連線IP位址、連線時間、連線次數、瀏覽、查詢、交易紀錄或下載之網頁、檔案及歷程、您輸入之任何文字或資料等，您同意本公司得自行以任何方式（包括置入Cookies等）蒐集、統計、分析及利用之。
            </div>

            <h4>免責聲明</h4>
            <div class="text">
              您於使用本網站服務時，宜自行採取防護措施，本公司對本網站全部或一部之正確性、完整性、可行性、即時性、相容性、無瑕疵性、無中斷性、無遺漏性及無損害性，不負任何擔保責任。 您可能藉由本網站連結至第三人網站或網頁，但不表示本公司與該等網站之所有人有任何關係，本公司對該第三人網站及網頁內之所有內容及服務，不負任何擔保責任。
            </div>

            <h4>損害賠償</h4>
            <div class="text">
              您明確瞭解並同意，任何因使用或無法使用本網站全部或一部所造成之直接、間接、偶發及衍生之損害賠償，包括但不限於您及第三人之財產上、非財產上、身體上、生命上及其他有形無形損害等，本公司不負任何損害賠償責任。 如您違反本網站使用聲明，本公司除得拒絕或暫停您瀏覽及使用本網站之全部或一部外，您並應對本公司負損害賠償責任（包括但不限於訴訟費用及律師費用）。
            </div>

            <h4>準據法及管轄法院</h4>
            <div class="text">
              本網站使用聲明之解釋與適用，以中華民國法律為準據法。因本網站使用聲明所生之任何爭議及糾紛，雙方應先尋求以協商方式解決，如未能解決者，雙方同意以台灣士林地方法院為第一審管轄法院。
            </div>

            <h4>自我保護措施</h4>
            <div class="text">
              請妥善保管您的任何個人資料、代號或密碼等，不要將任何個人資料，尤其是密碼提供給任何人或其他機構。若您是與他人共享電腦或使用公共電腦，使用後切記要關閉瀏覽器視窗，以防止他人讀取您的個人資料。
            </div>

            <h4>建議</h4>
            <div class="text">
              使用者如果對於本聲明或與個人資料有關之相關事項有任何疑問或建議，歡迎您透過電子郵遞或聯絡電話，提出 您的看法及意見。
            </div>
          </v-card-text>
          <v-checkbox
            v-model="isRead"
            color="primary"
            value="primary"
            :label="`我已詳閱並同意相關權益須知`"
          />
          <Btn
            btn-text="下一步"
            @click="handleConfirm"
          />
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  model: {
    event: ['confirm', 'close']
  },
  props: {
    open: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isActive: this.open,
      isRead: false
    }
  },
  watch: {
    open() {
      this.isActive = this.open
    },
    isActive() {
      this.$emit('update:open', this.isActive)
    }
  },
  methods: {
    handleCancel() {
      this.isActive = false
      this.$emit('close')
    },
    handleConfirm() {
      if (!this.isRead) {
        return this.$toast.open({
          message: '請勾選同意',
          type: 'warning'
        })
      }

      if (this.$listeners.confirm) {
        this.isActive = false
        this.$emit('confirm')
        return
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.statement-title {
  display: flex;
  justify-content: center;
}

.statement-content {
  background-color: $background-light;
  padding: 12px 14px !important;
  border-radius: 5px;

  h4 {
    color: #707070;
    text-align: center;

    &:not(:first-of-type) {
      margin-top: 16px;
    }
  }

  .text {
    font-size: 14px;
    color: #707070;
    text-align: left;
  }
}
</style>
