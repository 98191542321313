<template>
  <div id="email-confirm">
    <div class="container">
      <div class="page-wrapper">
        <div class="notice">
          驗證失敗，連結已失效<br>
          請重新註冊帳號
        </div>
        <div class="btn-block">
          <Btn
            class="btn"
            btn-text="前往LINE官方帳號"
            @click="toLine"
          />
          <Btn
            btn-text="前往Facebook"
            @click="toFacebook"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import linkMixins from '@/mixins/link'

export default {
  mixins: [linkMixins]
}
</script>

<style lang="scss" scoped>
#email-confirm {
  min-height: 100vh;
  .container {
    display:  flex;
    align-items: center;
    justify-content:  center;
  }
  .page-wrapper {
    width: 100%;
    padding: 0 30px;
  }
  .notice {
    color: $text-1;
    font-size: 20px;
    padding-bottom: 70px;
  }
  .btn {
    padding-bottom: 20px;
  }
}
</style>
