<template>
  <div id="point-page">
    <div class="container">
      <div class="title-wrapper">
        <div class="page-title">
          我的紅利點數
          <div class="my-point-block">
            <div class="my-point">
              {{ myPoint }}
            </div>
            <div class="point-text">
              點
            </div>
          </div>
        </div>
        <div class="recently-expired">
          {{ expiredDate }}到期：{{ expiredPoint }}點
        </div>
      </div>
      <div class="operation-btn">
        <div
          v-for="(btn, index) in btns"
          :key="index"
          class="btn-items"
          @click="handleChangePage(btn.link)"
        >
          {{ btn.label }}
        </div>
      </div>
      <div class="exchange-block">
        <div class="exchange-title">
          紅利兌換好禮
        </div>
        <PointGifts :data="normalizedAwards" />
        <!-- <Coupon
          :gifts="awards"
          :my-point="myPoint"
        /> -->
      </div>
      <div class="exchange-block">
        <div class="exchange-title">
          過期專區
        </div>
        <PointGifts
          is-expired
          :data="normalizedExpiredAwards"
        />
      </div>
      <MemberBtn
        class="big-btn"
        :is-active="value"
      />
    </div>
  </div>
</template>

<script>
import _ from 'lodash'
import dayjs from 'dayjs'
import { mapGetters } from 'vuex'
import helpers from '@/utils/helpers'

import authMixins from '@/mixins/auth'

import MemberBtn from '@/components/global/MemberBtn'
import PointGifts from '@/components/point/PointGifts'
export default {
  components: {
    MemberBtn,
    PointGifts
  },
  mixins: [authMixins],
  data() {
    return {
      value: 2
    }
  },
  computed: {
    ...mapGetters({
      userData: 'user/data',
      awards: 'award/list',
      expiredAwards: 'expiredAward/list'
    }),
    expiredDate() {
      const year = dayjs().format('YYYY')
      return `${year}-12-31`
    },
    normalizedAwards() {
      return this.transData(this.awards)
    },
    normalizedExpiredAwards() {
      return this.transData(this.expiredAwards)
    },
    myPoint() {
      return _.get(this.userData, 'points', 0)
    },
    expiredPoint() {
      return _.get(this.userData, 'expired_points', 0)
    },
    btns() {
      return [
        {
          label: '得獎名單',
          link: '/ms-web/list-of-winners'
        },
        {
          label: '紅利紀錄',
          link: '/ms-web/point-record'
        },
        {
          label: '我的優惠券',
          link: '/ms-web/coupon'
        }
      ]
    }
  },
  async mounted() {
    this.$store.dispatch('user/fetchData')
    await this.fetchAwards()
  },
  methods: {
    handleLogout() {
      this.removeJwtToken()
      this.$store.dispatch('user/resetData')
      this.$store.dispatch('auth/resetData')
      this.$router.push('/ms-web/logout')
    },
    transData(awards = []) {
      return awards.map((award) => {
        return {
          ...award,
          need_point: award.point,
          activity_end: award.end_date,
          draw_at: award.lottery_at,
          type: this.getType(award.method_id),
          image_url: this.getImageUrl(award)
        }
      })
    },
    getImageUrl(award) {
      const image_url = _.get(award, 'photos.0.url', '')
      if (!image_url) return require('@/assets/image/icon-gift.svg')
      return helpers.getUrl(image_url)
    },
    async fetchAwards() {
      this.$loading.start()
      this.$store.dispatch('award/fetchList')
      await this.$store.dispatch('expiredAward/fetchList')
      this.$loading.finish()
    },
    handleChangePage(link) {
      this.$router.push(link)
    },
    getType(key) {
      const keys = {
        1: 'exchange',
        2: 'online',
        3: 'offline'
      }
      return keys[key]
    }
  }
}
</script>

<style lang="scss" scoped>
#point-page {
  .title-wrapper {
    display: flex;
    flex-direction: column;
  }
  .my-point-block {
    display: flex;
    flex-direction: row;
    align-items: baseline;
  }
  .my-point {
    width: 102px;
    font-size: 32px;
    text-align: left;
  }
  .point-text {
    align-items: bottom;
    font-size: 16px;
  }
  .page-title {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding-bottom: 0px;
  }
  .recently-expired  {
    font-size: 14px;
    color: $text-2;
    text-align: right;
  }
  .operation-btn {
    padding-top: 20px;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
  }
  .btn-items {
    width: 33.33333%;
    border: 1px solid;
    border-radius: 8px;
    padding: 5px 8px;
    font-size: 14px;
    cursor: pointer;
    &:hover {
      color: $primary;
      border-color: $primary;
    }

    &:first-child {
      margin-right: 10px;
    }

    &:last-child {
      margin-left: 10px;
    }
  }
  .exchange-block {
    padding-top: 25px;
    text-align: left;
  }
  .exchange-title {
    padding-bottom: 10px;
    color: $text-1;
    font-size: 16px;
    font-weight: bold;
  }
  .logout-button {
    position: fixed;
    top: 20px;
    right: 12px;
  }

  @media screen and (min-width: 767px) {
    .big-btn {
      right: 70px;
      left: 70px;
    }
  }
}
</style>
