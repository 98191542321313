import { render, staticRenderFns } from "./bottom-bubble.vue?vue&type=template&id=e0a6aef8&scoped=true&"
var script = {}
import style0 from "./bottom-bubble.vue?vue&type=style&index=0&id=e0a6aef8&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e0a6aef8",
  null
  
)

export default component.exports