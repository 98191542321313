import api from '@/utils/api'

const state = () => ({
  data: {
    email: null
  }
})

const getters = {
  data(state) {
    return state.data
  },
  isManager(state) {
    return !!state.data.manager_id
  }
}

const mutations = {
  setData(state, data) {
    state.data = data
  },
  setDataByKey(state, { key, value }) {
    state.data[key] = value
  }
}

const actions = {
  setData({ commit }, data) {
    commit('setData', data)
  },
  setDataByKey({ commit }, { key, value }) {
    commit('setDataByKey', { key, value })
  },
  async fetchData({ commit }) {
    return new Promise((resolve, reject) => {
      api('USER_PROFILE').then((response) => {
        const { status, data } = response.data
        if (!status) {
          return reject()
        }

        commit('setData', data.data)
        resolve(data.data)
      }).catch((error) => {
        console.log('error', error)
        reject(error)
      })
    })
  },
  resetData({ commit }) {
    commit('setData', {
      email: null
    })
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
