<template>
  <div id="reset-ms-password">
    <div class="container">
      <v-form
        ref="form"
        lazy-validation
        @submit.prevent="handleResetPassword"
      >
        <PageTitle back>
          重新設定密碼
        </PageTitle>
        <VInput
          required
          input-title="設定密碼"
          label="請輸入8-20碼英數混合字"
          type="password"
          :value.sync="form.password"
          :rules="[
            rules.password
          ]"
        />
        <VInput
          required
          type="password"
          :value.sync="form.confirmPassword"
          input-title="再次輸入密碼"
          :rules="[
            rules.password
          ]"
        />
        <Btn
          class="bottom-btn"
          type="submit"
          btn-text="確認"
        />
      </v-form>
    </div>
  </div>
</template>

<script>
import formMixins from '@/mixins/form'
import { mapGetters } from 'vuex'
import api from '@/utils/api'

export default {
  mixins: [formMixins],
  data() {
    return {
      form: {
        name: null,
        password: null,
        confirmPassword: null
      },
      token: null,
      email: null
    }
  },
  computed: {
    ...mapGetters({
      userData: 'user/data'
    })
  },
  mounted() {
    this.email = this.userData.email
    this.token = this.userData.token

    if (!this.email && !this.token) {
      return this.$router.push('/ms-web/login')
    }
  },
  methods: {
    handleResetPassword() {
      const result = this.$refs.form.validate()
      if (!result) {
        return
      }

      this.$loading.start()
      if (this.form.password !== this.form.confirmPassword) {
        this.$loading.finish()
        this.$toast.open({
          message: '密碼和確認密碼不一致',
          type: 'error'
        })

        return
      }

      api('RESET_MS_PASSWORD', {
        data: {
          email: this.email,
          token: this.token,
          password: this.form.password
        }
      }).then((response) => {
        const { status, message } = response.data

        if (message && !status) {
          return this.$toast.open({
            message: this.$error.getMessage(message),
            type: 'error'
          })
        }

        // this.$toast.open({
        //   message: '更新密碼成功，請重新登入',
        //   type: 'success',
        // })
        this.$router.push('/ms-web/reset-password-success')
      }).then(() => {
        this.$loading.finish()
      })
    }
  }
}
</script>

<style lang="scss" scoped>
#reset-ms-password {
  min-height: 100vh;

  .btn {
    position: fixed;
    bottom: 23px;
    right: 23px;
    left: 23px;
  }
}
</style>
