import { apiBaseUrl } from '@/utils/api/variable'

const getOrders = (context, action, requestItems) => {
  const { data = {}} = requestItems

  return ({
    url: `${apiBaseUrl}/auth/productions/order/getList`,
    method: 'POST',
    data: {
      status: data.status,
      page: data.page || 1,
      pageSize: data.pageSize || 15
    }
  })
}

const getOrder = (context, action, requestItems) => {
  const { id } = requestItems

  return ({
    url: `${apiBaseUrl}/auth/productions/order/getOrder`,
    method: 'POST',
    data: {
      id
    }
  })
}

const bindOrder = (context, action, requestItems) => {
  const { data = {}} = requestItems

  return ({
    url: `${apiBaseUrl}/auth/productions/checkorder`,
    method: 'POST',
    data
  })
}

const getSurvey = (context, action, requestItems) => {
  const { data } = requestItems

  return ({
    url: `${apiBaseUrl}/auth/survey/getSurvey`,
    method: 'POST',
    data
  })
}

const updateSurvey = (context, action, requestItems) => {
  const { data } = requestItems

  return ({
    url: `${apiBaseUrl}/auth/survey/send`,
    method: 'POST',
    data
  })
}

const reviewSend = (context, action, requestItems) => {
  const { data = {}} = requestItems

  return ({
    url: `${apiBaseUrl}/auth/review/send`,
    method: 'POST',
    data
  })
}

export default {
  GET_ORDERS: getOrders,
  GET_ORDER: getOrder,
  BIND_ORDER: bindOrder,
  GET_SURVEY: getSurvey,
  UPDATE_SURVEY: updateSurvey,
  REVIEW_SEND: reviewSend
}
