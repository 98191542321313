<template>
  <div class="card-wrapper">
    <div class="image-wrapper">
      <div class="image-container">
        <img class="card-image" :src="currentRank.image">
      </div>
    </div>
    <div
      class="card-content"
      :class="handleCardClass"
    >
      <div class="title-block">
        <div class="level">
          {{ level }}
        </div>
        <div class="member-name">
          {{ userData.name }}
        </div>
      </div>
      <div class="point-wrapper">
        <div class="point-block">
          <div class="point-title">
            紅利點數
          </div>
          <div class="point">{{ point }}</div>
        </div>
        <div class="line" />
        <div class="point-block">
          <div class="id-title">
            會員ID
          </div>
          <div class="member-id">
            {{ userData.id }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import _ from 'lodash'
import { mapGetters } from 'vuex'

import rankMixins from '@/mixins/rank'

export default {
  mixins: [rankMixins],
  computed: {
    ...mapGetters({
      userData: 'user/data'
    }),
    level() {
      return this.currentRank.label || ''
    },
    point() {
      return _.get(this.userData, 'points', 0)
    },
    mileage() {
      return _.get(this.userData, 'mileage', 0)
    },
    handleCardClass() {
      const level = this.currentRank.id
      if (!level) return
      return `is-${level}`
    }
  }
}
</script>

<style lang="scss" scoped>
.card-wrapper {
  position: relative;
  .image-container {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    border-radius: 20px;
    img {
      object-fit: cover;
      width: 100%;
    }
  }
  .image-wrapper {
    position: relative;
    z-index: 1;
    width: 100%;
    padding-bottom: calc(100% * 190 / 343);
  }
  .card-image {
    border-radius: 20px;
    box-shadow: 0 0 4px gray;
  }
  .card-content {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 16px;
    z-index: 10;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .title-block {
    text-align: left;
  }
  .level {
    font-size: 14px;
    font-weight: bold;
  }
  .member-name {
    font-size: 20px;
    font-weight: bold;
  }
  .point-title {
    display: flex;
    justify-content: space-between;
    font-size: 14px;
    font-weight: bold;
  }
  .point {
    font-size: 16px;
    font-weight: bold;
  }
  .id-title {
    font-size: 14px;
    font-weight: bold;
  }
  .member-id {
    font-size: 16px;
    font-weight: bold;
  }
  .point-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .line {
    width: 1px;
    height: 70%;
  }
  .point-block {
    text-align: left;
    padding: 0 16px;
    &:first-of-type {
      padding-left: 0;
    }
    &:last-of-type {
      padding-right: 0;
    }
  }
  .card-content {
    &.is-black {
      .level {
        color: $card-black-2;
      }
      .member-name {
        color: $card-black-1;
      }
      .point-title {
        color: $card-black-2;
      }
      .point {
        color: $card-black-1;
      }
      .id-title {
        color: $card-black-2;
      }
      .member-id {
        color: $card-black-1;
      }
      .line {
        background-color: $card-black-2;
      }
    }

    &.is-gold {
      .level {
        color: $card-gold-1;
      }
      .member-name {
        color: $card-gold-2;
      }
      .point-title {
        color: $card-gold-3;
      }
      .point {
        color: $card-gold-2;
      }
      .id-title {
        color: $card-gold-3;
      }
      .member-id {
        color: $card-gold-2;
      }
      .line {
        background-color: $card-gold-2;
      }
    }

    &.is-blue {
      .level {
        color: $card-blue-1;
      }
      .member-name {
        color: $card-blue-2;
      }
      .point-title {
        color: $card-blue-3;
      }
      .point {
        color: $card-blue-2;
      }
      .id-title {
        color: $card-blue-3;
      }
      .member-id {
        color: $card-blue-2;
      }
      .line {
        background-color: $card-blue-2;
      }
    }
  }

  @media screen and (min-width: 767px) {
    .card-image {
      min-width: 330px;
    }
  }
}
</style>
