<template>
  <div class="dialog-block">
    <v-row justify="center">
      <v-dialog
        v-model="isActive"
        max-width="250"
      >
        <v-card class="dialog-card">
          <v-card-text>
            <div v-html="confirmationText" />
          </v-card-text>

          <v-card-actions>
            <v-spacer />

            <v-btn
              color="accent"
              outlined
              @click="handleTryAgain"
            >
              再試一次
            </v-btn>

            <v-btn
              color="green"
              outlined
              @click="handleConfirm"
            >
              重新註冊
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </div>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  model: {
    event: ['confirm', 'close']
  },
  props: {
    confirmationText: {
      type: String,
      default: ''
    },
    isOpen: {
      type: Boolean,
      default: false
    },
    link: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      isActive: this.isOpen
    }
  },
  watch: {
    isOpen() {
      this.isActive = this.isOpen
    },
    isActive() {
      this.$emit('update:isOpen', this.isActive)
    }
  },
  methods: {
    ...mapActions({
      setUserData: 'user/setData'
    }),
    handleTryAgain() {
      this.isActive = false
      // this.$router.push('/ms-web/forget-password')
    },
    handleConfirm() {
      this.isActive = false
      if (this.$listeners.confirm) {
        return this.$emit('confirm')
      }
    }
  }
}
</script>

<style land="scss" scoped>
.text-wrapper {
  white-space: pre-wrap;
}
</style>
