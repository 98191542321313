<template>
  <div>
    <v-btn
      outlined
      block
      color="primary"
      elevation="1"
      :type="type"
      @click="handleClick"
    >
      {{ btnText }}
    </v-btn>
  </div>
</template>

<script>
export default {
  model: {
    event: ['click']
  },
  props: {
    btnText: {
      type: String,
      default: ''
    },
    type: {
      type: String,
      default: 'button'
    }
  },
  methods: {
    handleClick() {
      this.$emit('click')
    }
  }
}
</script>

