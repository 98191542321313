import _ from 'lodash'
import Vue from 'vue'
import Router from 'vue-router'
import PageNotFound from '@/views/page-not-found'
// import Setting from '@/views/ms-web/setting'
// import Init from '@/views/ms-web/init'
import ManagerLogin from '@/views/ms-web/manager-login'
// import InitF from '@/views/ms-web/init-f'
import ServiceStatement from '@/views/ms-web/service-statement'
import Login from '@/views/ms-web/login'
import ForgetPassword from '@/views/ms-web/forget-password'
import Signup from '@/views/ms-web/signup'
import EmailValidateSend from '@/views/ms-web/email-validate-send'
// import DoubleBind from '@/views/ms-web/double-bind'
import ResetPassword from '@/views/ms-web/reset-password'
import ResetMSPassword from '@/views/ms-web/reset-ms-password'
import ResetPasswordSend from '@/views/ms-web/reset-password-send'
import ValidateLinkFailed from '@/views/ms-web/validate-link-failed'
import SignupSuccess from '@/views/ms-web/signup-success'
import EmailValidateFailed from '@/views/ms-web/email-validate-failed'
import MemberCenter from '@/views/ms-web/member-center'
import MemberInfo from '@/views/ms-web/member-info'
import Logout from '@/views/ms-web/logout'
import Destroy from '@/views/ms-web/destroy'
import Point from '@/views/ms-web/point'
import PointAwardDetail from '@/views/ms-web/point-award-detail'
import PointRecord from '@/views/ms-web/point-record'
import Coupon from '@/views/ms-web/coupon'
import ConsumptionRecords from '@/views/ms-web/consumption-records'
import ConsumptionDetail from '@/views/ms-web/consumption-detail'
import ConsumerQuestionnaire from '@/views/ms-web/consumer-questionnaire'
import UploadInvoice from '@/views/ms-web/upload-invoice'
import ResetPasswordSuccess from '@/views/ms-web/reset-password-success'
import ListOfWinners from '@/views/ms-web/list-of-winners'
import Redirect from '@/views/ms-web/redirect'

import ShopperRedirectToPoint from '@/views/shopper/redirect-to-point'
import ShopperLogin from '@/views/shopper/login'

import store from '@/store'
import { getJwtToken, removeJwtToken } from '@/utils/api/methods'

Vue.use(Router)

const router = new Router({
  mode: 'history',
  routes: [
    {
      path: '/ms-web/manager-login',
      name: 'ManagerLogin',
      component: ManagerLogin
    },
    {
      path: '/ms-web/service-statement',
      name: 'ServiceStatement',
      component: ServiceStatement
    },
    {
      path: '/ms-web/login',
      name: 'Login',
      component: Login
    },
    {
      path: '/ms-web/forget-password',
      name: 'ForgetPassword',
      component: ForgetPassword
    },
    {
      path: '/ms-web/signup',
      name: 'Signup',
      component: Signup
    },
    {
      path: '/ms-web/email-validate-send',
      name: 'EmailValidateSend',
      component: EmailValidateSend
    },
    // {
    //   path: '/ms-web/double-bind',
    //   name: 'DoubleBind',
    //   component: DoubleBind
    // },
    {
      path: '/ms-web/reset-password-send',
      name: 'ResetPasswordSend',
      component: ResetPasswordSend
    },
    {
      path: '/ms-web/reset-password',
      name: 'ResetPassword',
      component: ResetPassword,
      meta: {
        requireAuth: true
      }
    },
    {
      path: '/ms-web/reset-ms-password',
      name: 'ResetMSPassword',
      component: ResetMSPassword
    },
    {
      path: '/ms-web/validate-link-failed',
      name: 'ValidateLinkFailed',
      component: ValidateLinkFailed
    },
    {
      path: '/ms-web/signup-success',
      name: 'SignupSuccess',
      component: SignupSuccess
    },
    {
      path: '/ms-web/email-validate-failed',
      name: 'EmailValidateFailed',
      component: EmailValidateFailed
    },
    {
      path: '/ms-web/member-center',
      name: 'MemberCenter',
      component: MemberCenter,
      meta: {
        requireAuth: true
      }
    },
    {
      path: '/ms-web/member-info',
      name: 'MemberInfo',
      meta: {
        layout: 'BottomBubbleLayout',
        requireAuth: true
      },
      component: MemberInfo
    },
    {
      path: '/ms-web/logout',
      name: 'Logout',
      meta: {
        layout: 'BottomBubbleLayout'
      },
      component: Logout
    },
    {
      path: '/ms-web/destroy',
      name: 'Destroy',
      meta: {
        layout: 'BottomBubbleLayout'
      },
      component: Destroy
    },
    {
      path: '/ms-web/point-award',
      name: 'Point',
      component: Point,
      meta: {
        requireAuth: true
      }
    },
    {
      path: '/ms-web/point-award/:no',
      name: 'PointAwardDetail',
      component: PointAwardDetail,
      meta: {
        requireAuth: true
      }
    },
    {
      path: '/ms-web/list-of-winners',
      name: 'ListOfWinners',
      component: ListOfWinners,
      meta: {
        requireAuth: true
      }
    },
    {
      path: '/ms-web/point-record',
      name: 'PointRecord',
      meta: {
        layout: 'BottomBubbleLayout',
        requireAuth: true
      },
      component: PointRecord
    },
    {
      path: '/ms-web/coupon',
      name: 'Coupon',
      meta: {
        layout: 'BottomBubbleLayout',
        requireAuth: true
      },
      component: Coupon
    },
    {
      path: '/ms-web/reset-password-success',
      name: 'ResetPasswordSuccess',
      component: ResetPasswordSuccess
    },
    {
      path: '/ms-web/consumer-questionnaire/:id',
      name: 'ConsumerQuestionnaire',
      component: ConsumerQuestionnaire,
      meta: {
        requireAuth: true
      }
    },
    {
      path: '/ms-web/consumption-records',
      name: 'ConsumptionRecords',
      component: ConsumptionRecords,
      meta: {
        requireAuth: true
      }
    },
    {
      path: '/ms-web/consumption-records/:no',
      name: 'ConsumptionDetail',
      component: ConsumptionDetail,
      meta: {
        requireAuth: true
      }
    },
    {
      path: '/ms-web/consumption-records/:no/upload',
      name: 'UploadInvoice',
      component: UploadInvoice,
      meta: {
        requireAuth: true
      }
    },
    {
      path: '/ms-web/redirect',
      name: 'Redirect',
      component: Redirect
    },
    {
      path: '/shopper/redirect-to-point',
      name: 'ShopperRedirectToPoint',
      component: ShopperRedirectToPoint
    },
    {
      path: '/shopper/login',
      name: 'ShopperLogin',
      component: ShopperLogin
    },
    { path: '*', component: PageNotFound }
  ]
})

const handleLogout = (to, from, next) => {
  removeJwtToken()
  store.dispatch('user/resetData')
  next({ name: 'Login' })
}

router.beforeEach(async(to, from, next) => {
  // TODO: Fix loading
  // app.loading = true
  const { requireAuth } = to.meta || {}
  const isLogin = _.get(store, 'state.user.data.created_at')
  const token = getJwtToken()

  if (requireAuth) {
    if (!token) {
      return handleLogout(to, from, next)
    }

    if (token && !isLogin) {
      try {
        await store.dispatch('user/fetchData')
        next()
      } catch (err) {
        return handleLogout(to, from, next)
      }
    }
  }
  next()
})

router.afterEach(() => {
  // TODO: Fix loading
  // setTimeout(() => { app.loading = false }, 300)
})

export default router
