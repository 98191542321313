<template>
  <div class="line-redirect-page">
    <div class="box">
      <div class="title">
        Redirecting ...
      </div>
      <Circle4 />
      <router-link to="/ms-web/login">
        <v-btn
          class="link"
          type="primary"
        >
          Back to login page
        </v-btn>
      </router-link>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import Cookies from 'js-cookie'
import { Circle4 } from 'vue-loading-spinner'

import { removeJwtToken } from '@/utils/api/methods'
import api from '@/utils/api'
import authMixins from '@/mixins/auth'

export default {
  components: { Circle4 },
  mixins: [authMixins],
  computed: {
    token() {
      return this.$route.query.token
    }
  },
  mounted() {
    if (!this.token) return this.$router.push('/ms-web/login')
    setTimeout(() => {
      this.handleLogin()
    }, 1000)
  },
  methods: {
    ...mapActions({
      setUserData: 'user/setData'
    }),
    handleLogin() {
      removeJwtToken()
      api('LOGIN_FROM_MANAGER', {
        headers: {
          'Authorization': 'Bearer ' + this.token
        }
      }).then((response) => {
        console.log('response.data', response.data)
        const { status, message, data } = response.data

        if (message && !status) {
          return this.$toast.open({
            message: this.$error.getMessage(message),
            type: 'error',
            duration: 0
          })
        }

        Cookies.set('ms_access_token', data.access_token)
        this.setUserData(data.user)
        this.$toast.open({
          message: '登入成功',
          type: 'success'
        })
        this.$router.push('/ms-web/member-center')
      }).then(() => {
        this.$loading.finish()
      })
    }
  }
}
</script>

<style lang="scss" scoped>
$bg:#2d3a4b;

.line-redirect-page {
  min-height: 100vh;
  width: 100%;
  background-color: $bg;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;

  .title {
    margin-bottom: 12px;
    font-weight: bold;
  }

  .box {
    margin: 0 auto;
    width: 520px;
    max-width: 100%;
    background-color: #fff;
    border-radius: 14px;
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .link {
    margin-top: 20px;
  }
}
</style>
