<template>
  <div
    class="c-image"
    :style="imageStyle"
  >
    <img
      v-if="!!src"
      :src="src"
      @click="handleClickImage"
    >
    <div
      v-else
      class="empty-image"
    />
    <v-dialog
      :visible="showImage"
      append-to-body
      @close="handleClose"
    >
      <div>
        <img
          :src="src"
          :style="dialogImageStyle"
        >
      </div>
    </v-dialog>
  </div>
</template>

<script>
export default {
  props: {
    src: {
      type: String,
      default: ''
    },
    width: {
      type: [Number, String],
      default: 60
    },
    height: {
      type: [Number, String],
      default: 60
    }
  },
  data() {
    return {
      showImage: false
    }
  },
  computed: {
    imageStyle() {
      return {
        width: `${this.width}px`,
        height: `${this.height}px`
      }
    },
    dialogImageStyle() {
      return {
        'width': '100%',
        'object-fit': 'contain'
      }
    }
  },
  methods: {
    handleClose() {
      this.showImage = false
    },
    handleClickImage() {
      if (!this.src) {
        return
      }

      this.showImage = true
    }
  }
}
</script>

<style lang="scss" scoped>
.c-image {
  position: relative;
  border-radius: 6px;
  overflow: hidden;
  cursor: pointer;
  margin: auto;
  flex: 0 0 auto;

  img {
    width: 100%;
    height: 100%;
  }

  .empty-image {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background-color: #e2e2e2;
  }
}
</style>
