import Vue from 'vue'
import Vuex from 'vuex'
import auth from './auth'
import user from './user'
import order from './order'
import consumption from './consumption'
import coupon from './coupon'
import award from './award'
import expiredAward from './expiredAward'
import record from './point-record'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    auth,
    user,
    consumption,
    coupon,
    order,
    award,
    expiredAward,
    record
  }
})
