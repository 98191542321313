import { apiBaseUrl } from '@/utils/api/variable'

const getAwards = (context, action, requestItems) => {
  const { data = {}} = requestItems

  return ({
    url: `${apiBaseUrl}/auth/award/all`,
    method: 'POST',
    data
  })
}

const getAward = (context, action, requestItems) => {
  const { id } = requestItems

  return ({
    url: `${apiBaseUrl}/auth/award/get`,
    method: 'POST',
    data: {
      award_id: id
    }
  })
}

const getActivityAward = (context, action, requestItems) => {
  const { id } = requestItems

  return ({
    url: `${apiBaseUrl}/auth/award/getActivity`,
    method: 'POST',
    data: {
      award_activity_id: id
    }
  })
}

const getExpiredAwards = (context, action, requestItems) => {
  const { data = {}} = requestItems

  return ({
    url: `${apiBaseUrl}/auth/award/expired`,
    method: 'POST',
    data
  })
}

const exchangeAward = (context, action, requestItems) => {
  const { data = {}} = requestItems

  return ({
    url: `${apiBaseUrl}/auth/award/change`,
    method: 'POST',
    data
  })
}

const getAwardWinner = (context, action, requestItems) => {
  // const { data = {}} = requestItems

  return ({
    url: `${apiBaseUrl}/auth/award/winners`,
    method: 'GET'
  })
}

export default {
  GET_AWARDS: getAwards,
  GET_AWARD: getAward,
  GET_ACTIVITY_AWARD: getActivityAward,
  GET_EXPIRED_AWARDS: getExpiredAwards,
  EXCHANGE_AWARD: exchangeAward,
  GET_AWARD_WINNER: getAwardWinner
}
