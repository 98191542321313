import Methods from '../methods'
import orderConfig from './order'
import userConfig from './user'
import awardConfig from './award'
import couponConfig from './coupon'
import pointRecordConfig from './point-record'

const transConfig = (context, config) => {
  const jwtToken = Methods.getJwtToken(context)

  return {
    ...config,
    headers: {
      ...config.headers,
      Authorization: 'Bearer ' + jwtToken
    }
  }
}

const configs = {
  ...userConfig,
  ...orderConfig,
  ...awardConfig,
  ...couponConfig,
  ...pointRecordConfig
}

export const getConfig = (context, action, requestItems) => {
  const config = configs[action](context, action, requestItems)

  const noTransAction = [
    'CHECK_USER_STATUS',
    'REGISTER',
    'EMAIL_VERIFY',
    'LOGIN',
    'FORGET_PASSWORD_EMAIL',
    'RESET_PASSWORD_BY_EMAIL',
    'GET_CHECK_PRIVACY',
    'POST_CHECK_PRIVACY',
    'LOGIN_FROM_MANAGER'
  ]
  if (noTransAction.includes(action)) {
    return config
  }

  return transConfig(context, config)
}
