<template>
  <v-app id="app">
    <v-main>
      <!-- <v-overlay :value="$root.loading" /> -->
      <loading
        is-full-page
        can-cancel
        :on-cancel="onCancel"
        color="#1550D1"
        background-color="#d4d4d4"
        :active="$root.loading"
      />
      <component :is="currentLayout">
        <router-view />
      </component>
      <!-- <div
        class="update_at"
      >
        2021-10-13 11:06
      </div> -->
    </v-main>
  </v-app>
</template>

<script>
import _ from 'lodash'
import Loading from 'vue-loading-overlay'

import authMixins from '@/mixins/auth'

import DefaultLayout from '@/components/layout/default'
import BottomBubbleLayout from '@/components/layout/bottom-bubble'
import StatementDialog from '@/components/login/StatementDialog'
// import api from '@/utils/api'
import 'vue-loading-overlay/dist/vue-loading.css'

export default {
  name: 'App',
  components: { Loading, DefaultLayout, BottomBubbleLayout, StatementDialog },
  mixins: [authMixins],
  computed: {
    currentLayout() {
      const { meta } = this.$route

      return _.get(meta, 'layout', 'DefaultLayout')
    }
  },
  mounted() {
    const version = '1.0.1'
    console.log('version: ' + version)
  },
  methods: {
    onCancel() {
      this.$root.loading = false
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~vuetify/src/styles/main.sass';

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;

  &::v-deep .v-dialog {
    img {
      &.close-icon {
        margin: 0 0 10px 265px;
        width: 18px;
      }
    }
  }
  @media screen and (max-width: 320px) {
     &::v-deep .v-dialog {
      img {
        &.close-icon {
          margin: 0 0 10px 250px;
          width: 18px;
        }
      }
  }
  }

  &::v-deep .dialog-card {
    padding: 40px 12px 20px;

    .v-card__text {
      margin-bottom: 20px;
      padding: 0;
      font-size: 18px;
      line-height: 1.5;
      color: $text-1;
    }
    .v-card__actions {
      display: block;
    }
    .center-block {
      display: flex;
      justify-content: center;
      align-items: center;
      padding-bottom: 20px;
    }

    img {
      margin: 5px 0 0 230px;
    }
  }

  &::v-deep .container {
    min-height: 100vh;
    padding: $gap-y $gap-x;
    padding-bottom: 120px !important;
  }

  &::v-deep .bottom-btn {
    position: absolute;
    bottom: $gap-y;
    left: $gap-x;
    right: $gap-x;
  }

  &::v-deep .is-relative {
    position: relative;
  }

  .update_at {
    position: fixed;
    right: 4px;
    top: 4px;
    z-index: 99999;
    font-size: 12px;
  }
}
</style>
