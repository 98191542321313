<template>
  <div class="line-redirect-page">
    <div class="box">
      <div class="title">
        Redirecting ...
      </div>
      <Circle4 />
      <router-link to="/ms-web/login">
        <v-btn
          class="link"
          type="primary"
        >
          Back to login page
        </v-btn>
      </router-link>
    </div>
  </div>
</template>

<script>
import _ from 'lodash'
import { mapActions } from 'vuex'
import { Circle4 } from 'vue-loading-spinner'

import { setSerial } from '@/utils/api/methods'
import authMixins from '@/mixins/auth'
import helpers from '@/utils/helpers'

export default {
  components: { Circle4 },
  mixins: [authMixins],
  computed: {
    token() {
      return this.$route.query.token
    }
  },
  async mounted() {
    this.$root.loading = true
    const { query } = this.$route
    // console.log('this.$route', this.$route)
    // const serial = helpers.getSearchId(fullPath)
    console.log('query', query)
    let serial = _.get(query, 'id')
    if (!serial) {
      const state = query['liff.state']
      serial = helpers.getSerialId(state)
    }
    console.log('serial', serial)
    if (!serial || serial === 'undefined') {
      this.$toast.open({
        message: '找不到此序號，請再刷一次',
        type: 'warning'
      })
      this.$router.push({ name: 'Login' })
      this.$root.loading = false
      return
    }

    setSerial(serial)

    const token = this.getJwtToken()
    if (token && !this.isLogin) {
      try {
        await this.$store.dispatch('user/fetchData')
      } catch (error) {
        console.log('error', error)
      }
    }

    if (this.isManager) {
      await this.$store.dispatch('user/resetData')
    }

    if ((this.isWeb && !this.isLogin) || this.isManager) {
      await this.initLine()
      await this.initFacebook()
    }

    this.$nextTick(() => {
      if (this.isLogin) {
        return this.handleRedirect()
      }

      this.$toast.open({
        message: '請先註冊或登入',
        type: 'warning'
      })
      this.$router.push({ name: 'Login' })
    })

    this.$root.loading = false
  },
  methods: {
    ...mapActions({
      setUserData: 'user/setData'
    }),
    handleRedirect() {
      this.$root.loading = false
      this.$router.push({ name: 'MemberCenter' })
    }
  }
}
</script>

<style lang="scss" scoped>
$bg:#2d3a4b;

.line-redirect-page {
  min-height: 100vh;
  width: 100%;
  background-color: $bg;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;

  .title {
    margin-bottom: 12px;
    font-weight: bold;
  }

  .box {
    margin: 0 auto;
    width: 520px;
    max-width: 100%;
    background-color: #fff;
    border-radius: 14px;
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .link {
    margin-top: 20px;
  }
}
</style>
