<template>
  <div class="shopping-camera-btn">
    <v-btn
      outlined
      block
      color="primary"
      elevation="1"
      @click="handleClick"
    >
      <img
        class="camera-icon"
        :src="require('@/assets/image/icon-cam.svg')"
      >
      <div class="btn-text">
        消費集點
      </div>
    </v-btn>
  </div>
</template>

<script>
export default {
  model: {
    event: ['click']
  },
  methods: {
    handleClick() {
      this.$emit('click')
    }
  }
}
</script>

<style lang="scss" scoped>
.shopping-camera-btn {
  padding-top: 20px;
  .camera-icon {
    width: 23px;
  }
  .btn-text {
    padding-left: 8px;
    font-size: 16px;
  }
}
</style>
