<template>
  <div class="notice-dialog">
    <v-dialog
      v-model="isActive"
      max-width="250"
    >
      <v-card class="dialog-card">
        <v-card-text>
          {{ notice }}
        </v-card-text>

        <v-card-actions>
          <v-spacer />

          <v-btn
            v-if="!count"
            color="#757575"
            outlined
            @click="handleCancel"
          >
            取消
          </v-btn>
          <v-btn
            color="primary"
            outlined
            @click="handleConfirm"
          >
            確認
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  model: {
    event: ['confirm']
  },
  props: {
    isOpen: {
      type: Boolean,
      default: false
    },
    notice: {
      type: String,
      default: ''
    },
    count: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      isActive: this.isOpen
    }
  },
  watch: {
    isOpen() {
      this.isActive = this.isOpen
    },
    isActive() {
      this.$emit('update:isOpen', this.isActive)
    }
  },
  methods: {
    handleConfirm() {
      this.isActive = false
      this.$emit('confirm')
    },
    handleCancel() {
      this.isActive = false
    }
  }
}
</script>
