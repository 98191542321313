import _ from 'lodash'
import api from '@/utils/api'
import moment from 'moment'

const state = () => ({
  list: []
})

const getters = {
  list(state) {
    return state.list
  }
}

const mutations = {
  setList(state, list) {
    state.list = list
  },
  setUpdateDataToList(state, { id, data }) {
    const index = state.list.reduce((carry, item, index) => {
      if (item.id === id) {
        carry = index
      }

      return carry
    }, -1)

    if (index < 0) {
      return
    }

    state.list[index] = {
      ...state.list[index],
      ...data
    }
  },
  refresh(state) {
    const oldList = _.cloneDeep(state.list)
    state.list = []

    setTimeout(() => {
      state.list = oldList
    }, 200)
  }
}

const actions = {
  setList({ commit }, list) {
    commit('setList', list)
  },
  setUpdateDataToList({ commit }, { id, data }) {
    commit('setUpdateDataToList', { id, data })
  },
  async fetchList({ commit }, form) {
    try {
      const response = await api('GET_POINT_RECORDS', { data: form })
      const { status, data } = response.data
      if (status) {
        // const list = _.get(data, '0.data', []) // Pagination
        const list = _.get(data, '0', []).map((item) => {
          return {
            ...item,
            point: item.points,
            orderNo: item.order_serial,
            store: item.store_name,
            date: moment(item.created_at).format('YYYY-MM-DD HH:mm:ss')
          }
        })
        commit('setList', list)
      }
    } catch (error) {
      console.log('error', error)
    }
  },
  refresh({ commit }) {
    commit('refresh')
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
