import { apiBaseUrl } from '@/utils/api/variable'

const getPointsRecords = (context, action, requestItems) => {
  const { data = {}} = requestItems

  return ({
    url: `${apiBaseUrl}/auth/points/record`,
    method: 'POST',
    data: {
      start_date: data.start_date,
      page: data.page || 1,
      pageSize: data.pageSize || 999999999
    }
  })
}

export default {
  GET_POINT_RECORDS: getPointsRecords
}
