<template>
  <div id="point-record">
    <div class="container">
      <div class="title-block">
        <img
          class="left-icon"
          :src="require('@/assets/image/icon-left.svg')"
          @click="handleBack()"
        >
        <div class="page-title">
          紅利紀錄
        </div>
      </div>
      <div class="select-block">
        <v-select
          :items="items"
          label="搜尋條件"
          dense
          outlined
          :height="28"
          color="secondary"
          @change="handleChange"
        />
      </div>
      <PointRecordTable :records="records" />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import dayjs from 'dayjs'

import authMixins from '@/mixins/auth'

import PointRecordTable from '@/components/point/PointRecordTable'
export default {
  components: {
    PointRecordTable
  },
  mixins: [authMixins],
  computed: {
    ...mapGetters({
      records: 'record/list'
    }),
    items() {
      const day = dayjs()
      return [
        {
          'text': '全部',
          'value': null
        },
        {
          'text': '最近一個月',
          'value': day.subtract(1, 'month').format('YYYY-MM-DD')
        },
        {
          'text': '最近三個月',
          'value': day.subtract(3, 'month').format('YYYY-MM-DD')
        },
        {
          'text': '最近半年',
          'value': day.subtract(6, 'month').format('YYYY-MM-DD')
        }
      ]
    }
  },
  async mounted() {
    await this.fetchRecords()
  },
  methods: {
    async fetchRecords(form = {}) {
      this.$loading.start()
      await this.$store.dispatch('record/fetchList', form)
      this.$loading.finish()
    },
    handleBack() {
      this.$router.push('/ms-web/point-award')
    },
    handleChange(value) {
      const form = {
        start_date: value
      }
      this.fetchRecords(form)
    }
  }
}
</script>

<style lang="scss" scoped>
#point-record {
  .title-block {
    display: flex;
    flex-direction: row;
    padding-bottom: 15px;
  }
  .page-title {
    padding-bottom: 0;
    padding-left: 120px;
  }
  .left-icon {
    width: 10px;
  }
  .select-block {
    padding-top: 12px;
    width: 100%;
    display: flex;
    justify-content: flex-end;

    &::v-deep .v-input {
      width: 40%;
      flex: 0 0 auto;
    }

    &::v-deep .v-input__slot {
      min-height: auto;
    }

    &::v-deep .v-label {
      top: 5px;
      font-size: 12px;
    }

    &::v-deep .v-input__append-inner {
      margin-top: 3px;
    }

    &::v-deep .v-select__selections {
      font-size: 12px;
    }
  }
}
</style>
