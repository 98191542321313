<template>
  <div class="input-field">
    <div class="input-title">
      {{ inputTitle }}
    </div>
    <v-text-field
      v-model="innerValue"
      :type="type"
      :required="required"
      :readonly="readonly"
      :label="label"
      :rules="rules"
      hide-details="auto"
      color="secondary"
      size="small"
    />
  </div>
</template>

<script>
export default {
  props: {
    inputTitle: {
      type: String,
      default: ''
    },
    label: {
      type: String,
      default: ''
    },
    rules: {
      type: Array,
      default() {
        return []
      }
    },
    value: {
      type: [String, Number],
      default: null
    },
    required: {
      type: Boolean,
      default: false
    },
    readonly: {
      type: Boolean,
      default: false
    },
    type: {
      type: String,
      default: 'text'
    }
  },
  data() {
    return {
      innerValue: this.value
    }
  },
  watch: {
    value() {
      this.innerValue = this.value
    },
    innerValue() {
      this.$emit('update:value', this.innerValue)
    }
  }
}
</script>

<style lang="scss" scoped>
.input-field {
  text-align: left;

  &:not(:last-of-type) {
    margin-bottom: 16px;
  }
}
.input-title {
  text-align: left;
  color: $text-1;
  font-size: 14px;
}
</style>
