<template>
  <div class="section-wrapper">
    <div class="mileage-block">
      <div class="mileage-detail">
        <div class="mileage-title">
          當前里程數
        </div>
        <div class="mileage">
          {{ mileage }}
        </div>
      </div>
      <v-progress-linear
        v-if="!isBlack"
        :height="6"
        rounded
        :value="progressValue"
        color="#2E424F"
        background-color="#2E424F"
        background-opacity="0.3"
      />
      <div v-if="!isBlack" class="notice">
        再{{ reminderMileage }}里程即可升級為{{ nextRank.label }}
      </div>
    </div>
    <div class="next-block">
      <div v-if="!isBlack" class="next-title  mt-6">Next level</div>
      <div v-if="!isBlack" class="img-wrapper">
        <img v-if="isGold" src="@/assets/image/card-1.svg">
        <img v-if="isBlue" src="@/assets/image/card-2.svg">
      </div>
      <div v-else class="next-title">已是最高級會員</div>
    </div>
  </div>
</template>

<script>
import rankMixins from '@/mixins/rank'

export default {
  mixins: [rankMixins],
  computed: {
    mileage() {
      return this.userData.mileage
    },
    reminderMileage() {
      const nextRankPoint = this.nextRank.point || 0
      if (nextRankPoint <= 0) return 0
      return nextRankPoint - this.mileage
    },
    progressValue() {
      return 100 - this.reminderMileage / this.nextPointGap * 100
    }
  }
}
</script>

<style lang="scss" scoped>
.section-wrapper {
  margin: 0 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  box-shadow: 0 0 4px #0000001f;
  border-bottom-right-radius: 20px;
  border-bottom-left-radius: 20px;

  .mileage-block {
    width: calc(100% / 3 * 2 );
    background-color: #EAF3FB;
    padding: 9px;
    display: flex;
    flex-direction:  column;
    justify-content: center;
    border-bottom-left-radius: 20px;
  }

  .mileage-detail {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 4px;
  }

  .mileage-title {
    font-size: 14px;
    font-weight: bold;
  }

  .mileage {
    font-size: 20px;
    font-weight: bold;
  }

  .notice {
    margin-top: 8px;
    font-size: 12px;
    color: #949494;
  }

  .next-block {
    padding: 10px 10px;
    width: calc(100% / 3 );
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .next-title {
    font-size: 12px;
    font-weight: bold;
    color: #2E424FB2;
  }
  .mt-6 {
    margin-top: 0 !important;
    margin-bottom: 6px;
  }
}
</style>
