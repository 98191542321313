<template>
  <div class="record-table">
    <v-simple-table
      fixed-header
    >
      <template #default>
        <thead>
          <tr>
            <th class="text-left">
              項目
            </th>
            <th class="text-left">
              點數
            </th>
            <th class="text-center">
              日期
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(record, index) in records"
            :key="index"
          >
            <td>
              <div class="item-wrapper">
                <div class="type">
                  {{ getTypeLabel(record.type) }}
                </div>
                <div
                  v-if="record.type === '產品登錄'"
                  class="item-block"
                >
                  <div class="order-no">
                    訂單編號：{{ record.orderNo }}
                  </div>
                  <div class="store">
                    購買門市：{{ record.store }}
                  </div>
                </div>
                <div class="item-block">
                  <div class="exchange-content">
                    {{ record.product_name }}
                  </div>
                </div>
              </div>
            </td>
            <td
              class="point"
              :class="getColor(record.point)"
            >
              {{ getPointText(record.point) }}
            </td>
            <td class="date">
              {{ record.date }}
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
  </div>
</template>

<script>
export default {
  props: {
    records: {
      type: Array,
      default: () => []
    }
  },
  methods: {
    getColor(point) {
      if (point > 0) {
        return 'is-blue'
      }
      if (point < 0) {
        return 'is-red'
      }
    },
    getPointText(point) {
      if (point > 0) {
        return '+' + point
      }
      if (point < 0) {
        return '-' + point * -1
      }
    },
    getTypeLabel(name) {
      if (name === '產品登錄') return '產品銷售'
      return '紅利兌換'
    }
  }
}
</script>

<style lang="scss" scoped>
.record-table {
  margin-top: -10px;
  .item-wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  .type {
    font-size: 14px;
  }
  .order-no, .store, .exchange-content {
    font-size: 12px;
    text-align: left;
  }
  .point {
    font-size: 14px;
    &.is-blue {
      color: $primary;
    }
    &.is-red {
      color: red;
    }
  }
  .date {
    font-size: 12px;
  }
  &::v-deep .v-data-table {
    background-color: transparent;
  }
  &::v-deep th {
    padding: 8px !important;
    height: 35px !important;
    color: $text-1 !important;
    box-shadow: inset 0 -1px 0 $text-1 !important;
  }
  &::v-deep td {
    border-bottom: 1.2px solid $background-light !important;
    padding: 5px 8px !important;
    height: 68.20px !important;
  }
}
</style>
