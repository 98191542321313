import _ from 'lodash'
import api from '@/utils/api'

const state = () => ({
  list: [],
  data: {}
})

const getters = {
  list(state) {
    return state.list
  },
  data(state) {
    return state.data
  }
}

const mutations = {
  setList(state, list) {
    state.list = list
  },
  setData(state, data) {
    state.data = data
  },
  setUpdateDataToList(state, { id, data }) {
    const index = state.list.reduce((carry, item, index) => {
      if (item.id === id) {
        carry = index
      }

      return carry
    }, -1)

    if (index < 0) {
      return
    }

    state.list[index] = {
      ...state.list[index],
      ...data
    }
  },
  refresh(state) {
    const oldList = _.cloneDeep(state.list)
    state.list = []

    setTimeout(() => {
      state.list = oldList
    }, 200)
  }
}

const actions = {
  setList({ commit }, list) {
    commit('setList', list)
  },
  setData({ commit }, data) {
    commit('setData', data)
  },
  setUpdateDataToList({ commit }, { id, data }) {
    commit('setUpdateDataToList', { id, data })
  },
  async fetchList({ commit }) {
    try {
      const response = await api('GET_EXPIRED_AWARDS')
      const { status, data } = response.data
      if (status) {
        // const list = _.get(data, '0.data', []) // Pagination
        const list = _.toArray(_.get(data, '0', []))
        commit('setList', list)
      }
    } catch (error) {
      console.log('error', error)
    }
  },
  refresh({ commit }) {
    commit('refresh')
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
