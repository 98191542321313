<template>
  <div class="shopper-login-page">
    <div class="box">
      <v-form
        ref="loginForm"
        class="login-form"
        lazy-validation
        @submit.prevent="handleLogin"
      >
        <div class="page-title">
          店員登入
        </div>
        <VInput
          required
          type="email"
          :value.sync="form.email"
          input-title="帳號"
        />
        <VInput
          required
          type="password"
          :value.sync="form.password"
          input-title="密碼"
          label="請輸入密碼"
          :rules="[
            rules.password
          ]"
        />
        <Btn
          class="login-button"
          type="submit"
          btn-text="登入"
        />
      </v-form>
    </div>
  </div>
</template>

<script>
import _ from 'lodash'
import api from '@/utils/api'
import formMixins from '@/mixins/form'
import { setJwtToken } from '@/utils/api/methods'

export default {
  mixins: [formMixins],
  data() {
    return {
      form: {
        email: '',
        password: ''
      }
    }
  },
  methods: {
    getFormData() {
      console.log('this.$route', this.$route)
      const provider = _.get(this.$route, 'query.provider', '')
      const bundle_id = _.get(this.$route, 'query.bundle_id', '')
      const data = {
        ...this.form,
        provider,
        bundle_id
      }

      console.log('data', data)
      return data
    },
    handleLogin() {
      if (!this.form.email || !this.form.password) {
        return this.$toast.open({
          message: '請輸入帳號密碼',
          type: 'error'
        })
      }

      api('SHOPPER_LOGIN', { data: this.getFormData() })
        .then((response) => {
          const { status, message } = response.data
          if (message && !status) {
            return this.$toast.open({
              message: this.$error.getMessage(message),
              type: 'error',
              duration: 0
            })
          }

          this.callLogin()
        })
        .catch(() => {})
    },
    callLogin() {
      api('SHOPPER_LOGIN', { data: this.getFormData() })
        .then((response) => {
          const { status, message, data } = response.data
          if (message && !status) {
            return this.$toast.open({
              message: this.$error.getMessage(message),
              type: 'error',
              duration: 0
            })
          }

          setJwtToken(data.access_token)
          this.$store.dispatch('user/setData', data.user)
          this.$toast.clear()
          this.$toast.open({
            message: '登入成功',
            type: 'success'
          })
          this.$router.push('/ms-web/point-award')
        })
        .catch(() => {})
    }
  }
}
</script>

<style lang="scss" scoped>
$bg:#2d3a4b;

.shopper-login-page {
  min-height: 100vh;
  width: 100%;
  background-color: $bg;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;

  .title {
    margin-bottom: 12px;
    font-weight: bold;
  }

  .box {
    margin: 0 auto;
    width: 520px;
    max-width: 100%;
    background-color: #fff;
    border-radius: 14px;
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .link {
    margin-top: 20px;
  }

  .login-form {
    padding: 12px;
    width: 100%;
  }
  .login-button {
    margin-top: 24px;
  }
}
</style>
