
<template>
  <div id="reset-pwd">
    <div class="container">
      <div class="page-wrapper">
        <div class="page-title">
          重新設定密碼
        </div>
        <div class="notice">
          密碼重設信件已寄送至<br>
          {{ user.email }}<br>
          <br>
          請至信箱點擊連結重新設定密碼
        </div>
        <div class="bottom-btn">
          <Btn
            class="btn"
            btn-text="重新發送"
            @click="isOpenReSendDialog = true"
          />
          <Btn
            btn-text="電子信箱錯誤"
            @click="isOpenReSignupDialog = true"
          />
        </div>
      </div>
    </div>
    <Dialog
      confirmation-text="確認要重設密碼嗎？"
      :is-open.sync="isOpenReSendDialog"
      @confirm="handleReSend"
    />
    <Dialog
      confirmation-text="電子信箱錯誤，要換一個信箱重新註冊嗎？"
      :is-open.sync="isOpenReSignupDialog"
      @confirm="handleReSignup"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import api from '@/utils/api'
export default {
  data() {
    return {
      isOpenReSendDialog: false,
      isOpenReSignupDialog: false
    }
  },
  computed: {
    ...mapGetters({
      user: 'user/data'
    })
  },
  mounted() {
    if (!this.user.email) {
      this.$root.loading = true
      setTimeout(() => {
        this.$router.push('/ms-web/login')
      }, 200)
    }
  },
  methods: {
    handleReSend() {
      this.$loading.start()
      api('FORGET_PASSWORD_EMAIL', {
        data: {
          email: this.user.email
        }
      }).then((response) => {
        const { status, message } = response.data

        if (message && !status) {
          return this.$toast.open({
            message: this.$error.getMessage(message),
            type: 'error'
          })
        }

        this.$toast.open({
          message: '驗證信已重新寄出',
          type: 'success'
        })
      }).then(() => {
        this.$loading.finish()
      })
    },
    handleReSignup() {
      this.$store.dispatch('user/resetData')
      this.$router.push('/ms-web/login')
    }
  }
}
</script>

<style lang="scss" scoped>
#reset-pwd {
  .notice {
    padding: 80px 40px;
    color: $text-1;
    font-size: 14px;
  }

  .btn {
    padding-bottom: 15px;
  }
}
</style>
