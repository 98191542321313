<template>
  <div class="notice-dialog">
    <v-dialog
      v-model="isActive"
      max-width="300"
    >
      <v-card class="dialog-card">
        <v-card-text>
          {{ data.name }}
        </v-card-text>
        <div class="list">
          {{ data.winners.names }}
        </div>

        <v-card-actions>
          <v-spacer />

          <v-btn
            color="primary"
            outlined
            @click="handleConfirm"
          >
            確認
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  props: {
    isOpen: {
      type: Boolean,
      default: false
    },
    data: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      isActive: this.isOpen
    }
  },
  watch: {
    isOpen() {
      this.isActive = this.isOpen
    },
    isActive() {
      this.$emit('update:isOpen', this.isActive)
    }
  },
  methods: {
    handleConfirm() {
      this.isActive = false
    }
  }
}
</script>

<style lang="scss" scoped>
.list {
  white-space: pre-line;
  padding: 0 10px;
  margin-bottom: 20px;
  text-align: left;
  font-size: 14px;
  max-height: 70vh;
  overflow: auto;
}
</style>
