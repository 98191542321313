<template>
  <div id="questionnaire">
    <div class="container">
      <div class="page-title">
        消費體驗心得問卷
      </div>
      <v-form>
        <div v-if="noQuestion">
          沒有心得問卷
        </div>
        <div v-if="!noQuestion">
          <div v-if="survey.description" class="description">
            問卷說明：{{ survey.description }}
          </div>
          <div v-for="(question, index) in questions" :key="index" class="survey-wrapper">
            <div class="heading">
              {{ question.heading }} （{{ handleType(question.type) }}）
            </div>
            <v-select
              v-if="question.type !== 'input'"
              v-model="form.answer[question.id]"
              class="select"
              :multiple="question.type === 'multiple'"
              :items="question.options"
              placeholder="請選擇答案"
              outlined
            />
            <v-textarea
              v-if="question.type === 'input'"
              v-model="form.answer[question.id]"
              outlined
              label="請填寫答案"
              class="textarea"
            />
          </div>
        </div>
        <div class="bottom-btn">
          <Btn
            v-if="!noQuestion"
            class="btn"
            btn-text="送出"
            @click="handleSubmit"
          />
          <v-btn
            text
            color="primary"
            @click="handleSkip"
          >
            略過
          </v-btn>
        </div>
      </v-form>
    </div>
  </div>
</template>

<script>
import api from '@/utils/api'

export default {
  data() {
    return {
      survey: {},
      opitons: [],
      form: {
        answer: {}
      }
    }
  },
  computed: {
    noQuestion() {
      return !this.survey
    }
  },
  mounted() {
    // TODO: 有登入，且經由 Qrcode 進入
    this.getSurvey()
  },
  methods: {
    handleType(type) {
      switch (type) {
        case 'select':
          return '單選題'
        case 'multiple':
          return '複選題'
        case 'input':
          return '簡答題'
      }
    },
    async getSurvey() {
      const { id } = this.$route.params
      const formData = {
        order_id: id
      }
      const response = await api('GET_SURVEY', { data: formData })
      const { status, message, data } = response.data
      if (!status) {
        this.$toast.open({
          message: this.$error.getMessage(message),
          type: 'error',
          duration: 5000
        })
        this.$router.push({ name: 'MemberCenter' })
        return
      }
      this.survey = data
      this.questions = JSON.parse(data.options).map((question) => {
        return {
          ...question,
          options: question.options.map((option) => {
            return {
              text: option.label,
              value: option.label
            }
          })
        }
      })
    },
    toRecordUpload() {
      const { id } = this.$route.params
      this.$router.push(`/ms-web/consumption-records/${id}/upload`)
    },
    handleSubmit() {
      this.$loading.start()
      const { id } = this.$route.params
      api('UPDATE_SURVEY', {
        data: {
          order_id: id,
          answer: JSON.stringify(this.form.answer)
        }
      }).then((response) => {
        console.log('response.data', response.data)
        const { status, message } = response.data

        if (message && !status) {
          return this.$toast.open({
            message: this.$error.getMessage(message),
            type: 'error'
          })
        }
        this.$toast.open({
          message: '填寫成功',
          type: 'success'
        })
        this.toRecordUpload()
      }).catch((error) => {
        console.log('error', error)
      }).finally(() => {
        this.$loading.finish()
      })
    },
    handleSkip() {
      // submit empty date
      this.toRecordUpload()
    }
  }
}
</script>

<style lang="scss" scoped>
#questionnaire {
  .btn {
    padding-bottom: 15px;
  }
  .description {
    margin-bottom: 20px;
    text-align: left;
  }
  .survey-wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  ::v-deep .v-input{
    width: 100%;
  }
  ::v-deep .input-field{
    width: 100%;
  }
  .textarea, .select {
    margin-top: 6px;
  }
}
</style>
