<template>
  <div class="lottery-process-dialog">
    <v-dialog
      v-model="isActive"
      max-width="250"
    >
      <v-card class="dialog-card">
        <div class="center-block">
          <Circle4 />
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { Circle4 } from 'vue-loading-spinner'

export default {
  components: { Circle4 },
  props: {
    isOpen: {
      type: Boolean,
      default: false
    },
    notice: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      isActive: this.isOpen
    }
  },
  watch: {
    isOpen() {
      this.isActive = this.isOpen
    },
    isActive() {
      this.$emit('update:isOpen', this.isActive)
    }
  },
  methods: {
    handleConfirm() {
      this.isActive = false
    }
  }
}
</script>
