<template>
  <div class="forget-password-page">
    <div class="container">
      <div class="page-title">
        忘記密碼
      </div>

      <v-form
        ref="form"
        lazy-validation
        @submit.prevent="handleValidateAnswers"
      >
        <div class="notice">
          請回答以下問題驗證您的身份，協助您重新設定密碼。
        </div>
        <div class="title-notice">
          安全提示問題1
        </div>
        <VInput
          required
          :value.sync="form.safety_one"
          input-title="請問您養的第一個寵物叫什麼名字？"
          label="請輸入答案"
        />
        <div class="title-notice">
          安全提示問題2
        </div>
        <VInput
          required
          input-title="請問您18歲生日收到什麼禮物？"
          label="請輸入答案"
          :value.sync="form.safety_two"
        />
        <Btn
          class="bottom-btn"
          type="submit"
          btn-text="下一步"
        />
      </v-form>
      <ForgetPasswordDialog
        confirmation-text="您的答案錯誤，</br>無法驗證您的身份，</br>如有疑問請輸入r聯繫小編"
        :is-open.sync="openWrongDialog"
        @confirm="handleSignupAgain"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import api from '@/utils/api'
export default {
  data() {
    return {
      openWrongDialog: false,
      form: {
        safety_one: null,
        safety_two: null
      }
    }
  },
  computed: {
    ...mapGetters({
      userData: 'user/data'
    })
  },
  mounted() {
    if (!this.userData.email) {
      this.$router.push('/ms-web/login')
    }
  },
  methods: {
    ...mapActions({
      setUserData: 'user/setData'
    }),
    handleValidateAnswers() {
      this.$loading.start()
      api('FORGET_PASSWORD', {
        data: {
          email: this.userData.email,
          safety_one: this.form.safety_one,
          safety_two: this.form.safety_two
        }
      }).then((response) => {
        const { data, message, status } = response.data
        if (message && !status) {
          this.openWrongDialog = true
          return
        }
        const userData = {
          email: data.email,
          token: data.token
        }
        this.setUserData(userData)
        this.$router.push('/ms-web/reset-ms-password')
      }).then(() => {
        this.$loading.finish()
      })
    },
    handleSignupAgain() {
      const userData = {
        email: null
      }
      this.setUserData(userData)
      this.$router.push('/ms-web/signup')
    }
  }
}
</script>

<style lang="scss" scoped>
.forget-password-page {
  min-height: 100vh;

  .notice {
    text-align: left;
    color: $text-1;
    font-size: 14px;
    margin-bottom: 20px;
  }
  .title-notice {
    text-align: left;
    color: $text-1;
    font-size: 14px;
    margin-bottom: 6px;
  }
}
</style>
