var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"signup-page"}},[(_vm.step === 0)?_c('div',{staticClass:"container"},[_c('v-form',{ref:"signFormRef",staticClass:"signup-wrapper",attrs:{"lazy-validation":""},on:{"submit":function($event){$event.preventDefault();return _vm.handleNext.apply(null, arguments)}}},[_c('div',{staticClass:"page-title"},[_vm._v(" 會員註冊 ")]),_c('VInput',{attrs:{"readonly":"","type":"email","value":_vm.userData.email,"input-title":"帳號"}}),_c('VInput',{attrs:{"required":"","input-title":"暱稱（請勿使用真實姓名）","value":_vm.form.name,"rules":[
          _vm.rules.required
        ]},on:{"update:value":function($event){return _vm.$set(_vm.form, "name", $event)}}}),_c('VInput',{attrs:{"required":"","input-title":"設定密碼","label":"請輸入8-20碼英數混合字","type":"password","value":_vm.form.password,"rules":[
          _vm.rules.password
        ]},on:{"update:value":function($event){return _vm.$set(_vm.form, "password", $event)}}}),_c('VInput',{attrs:{"required":"","value":_vm.form.confirmPassword,"input-title":"再次輸入密碼","type":"password","rules":[
          _vm.rules.password
        ]},on:{"update:value":function($event){return _vm.$set(_vm.form, "confirmPassword", $event)}}}),_c('Btn',{staticClass:"bottom-btn",attrs:{"type":"submit","btn-text":"下一步"}})],1)],1):_vm._e(),(_vm.step === 1)?_c('div',{staticClass:"container"},[_c('v-form',{ref:"questionFormRef",staticClass:"signup-wrapper",attrs:{"lazy-validation":""},on:{"submit":function($event){$event.preventDefault();return _vm.handleSignup.apply(null, arguments)}}},[_c('div',{staticClass:"page-title"},[_vm._v(" 安全提示問題 ")]),_c('div',{staticClass:"notice"},[_vm._v(" 若您日後忘記密碼，以下問題將用來驗證您的身份，協助您重新設定密碼。 ")]),_c('div',{staticClass:"title-notice"},[_vm._v(" 安全提示問題1 ")]),_c('VInput',{attrs:{"required":"","value":_vm.form.safety_one,"input-title":"請問您養的第一個寵物叫什麼名字？","label":"請輸入答案","rules":[
          _vm.rules.required
        ]},on:{"update:value":function($event){return _vm.$set(_vm.form, "safety_one", $event)}}}),_c('div',{staticClass:"title-notice"},[_vm._v(" 安全提示問題2 ")]),_c('VInput',{attrs:{"required":"","input-title":"請問您18歲生日收到什麼禮物？","label":"請輸入答案","value":_vm.form.safety_two,"rules":[
          _vm.rules.required
        ]},on:{"update:value":function($event){return _vm.$set(_vm.form, "safety_two", $event)}}}),_c('Btn',{staticClass:"bottom-btn",attrs:{"type":"submit","btn-text":"註冊"}})],1)],1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }