<template>
  <div id="my-coupon">
    <div class="container">
      <PageTitle back="/ms-web/point-award">
        我的優惠券
      </PageTitle>
      <!-- <div class="operation-btn">
        <div
          v-for="(btn, index) in btns"
          :key="index"
          class="btn-items"
          @click="handleChangeTab(btn.value)"
        >
          {{ btn.label }}
        </div>
      </div> -->
      <div
        v-if="!used"
        class="coupon-wrapper"
      >
        <div class="notice">
          提醒：具體使用請參見票券平台與門市。
        </div>
        <div class="select-block">
          <v-select
            :items="items"
            label="搜尋條件"
            dense
            outlined
            :height="28"
            color="secondary"
            @change="handleChange"
          />
        </div>
        <Coupon
          store
          is-my-coupon
          :coupons="coupons"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Coupon from '@/components/point/Coupon'

import authMixins from '@/mixins/auth'
import { mapGetters } from 'vuex'
export default {
  components: {
    Coupon
  },
  mixins: [authMixins],
  data() {
    return {
      used: false,
      isEdit: false,
      openExplanation: false,
      status: 'all'
    }
  },
  computed: {
    ...mapGetters({
      coupons: 'coupon/list'
    }),
    items() {
      return [
        {
          'value': 'all',
          'text': '全部'
        },
        {
          'value': 'can_used',
          'text': '可兌換'
        },
        {
          'value': 'expired',
          'text': '已過期'
        }
      ]
    }
  },
  async mounted() {
    await this.fetchCoupons()
  },
  methods: {
    fetchCoupons() {
      this.$loading.start()
      const data = {
        status: this.status
      }
      this.$store.dispatch('coupon/fetchList', { data })
      this.$loading.finish()
    },
    handleBack() {
      this.$router.push('/ms-web/point-award')
    },
    handleEditCoupon() {
      this.isEdit = true
    },
    handleEditCouponFinish() {
      this.isEdit = false
    },
    handleChangeTab(value) {
      if (value === 1) {
        this.used = false
      }
      if (value === 2) {
        this.used = true
      }
    },
    handleOpenExplanation() {
      this.openExplanation = true
    },
    handleChange(value) {
      this.status = value
      this.fetchCoupons()
    }
  }
}
</script>

<style lang="scss" scoped>
#my-coupon {
  .select-block {
    padding-top: 12px;
    width: 100%;
    display: flex;
    justify-content: flex-end;

    &::v-deep .v-input {
      width: 40%;
      flex: 0 0 auto;
    }

    &::v-deep .v-input__slot {
      min-height: auto;
    }

    &::v-deep .v-label {
      top: 5px;
      font-size: 12px;
    }

    &::v-deep .v-input__append-inner {
      margin-top: 3px;
    }

    &::v-deep .v-select__selections {
      font-size: 12px;
    }
  }

  .rule-block {
    display: flex;
    justify-content: flex-end;
  }

  .notice {
    font-size: 12px;
    color: $text-2;
    text-align: left;
  }
}
</style>
