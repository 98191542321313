<template>
  <div class="dialog-block">
    <v-row justify="center">
      <v-dialog
        v-model="isActive"
        max-width="250"
      >
        <v-card class="dialog-card">
          <v-card-text>
            {{ confirmationText }}
          </v-card-text>

          <v-card-actions>
            <v-spacer />

            <v-btn
              color="accent"
              outlined
              @click="handleCancel"
            >
              取消
            </v-btn>

            <v-btn
              color="green"
              outlined
              @click="handleConfirm"
            >
              確認
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </div>
</template>

<script>
export default {
  model: {
    event: ['confirm', 'close']
  },
  props: {
    confirmationText: {
      type: String,
      default: ''
    },
    isOpen: {
      type: Boolean,
      default: false
    },
    link: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      isActive: this.isOpen
    }
  },
  watch: {
    isOpen() {
      this.isActive = this.isOpen
    },
    isActive() {
      this.$emit('update:isOpen', this.isActive)
    }
  },
  methods: {
    handleCancel() {
      this.isActive = false
      this.$emit('close')
    },
    handleConfirm() {
      this.isActive = false
      if (this.$listeners.confirm) {
        return this.$emit('confirm')
      }
      if (this.link) {
        this.$router.push(this.link)
      }
    }
  }
}
</script>
