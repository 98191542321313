import { apiBaseUrl } from '@/utils/api/variable'

const checkUserStatus = (context, action, requestItems) => {
  const { data = {}} = requestItems

  return ({
    url: `${apiBaseUrl}/user/checkstatus`,
    method: 'POST',
    data: {
      email: data.email,
      type: data.type,
      bundle_id: data.bundle_id
    }
  })
}

const register = (context, action, requestItems) => {
  const { data = {}} = requestItems

  return ({
    url: `${apiBaseUrl}/register`,
    method: 'POST',
    data: {
      email: data.email,
      name: data.name,
      password: data.password,
      safety_one: data.safety_one,
      safety_two: data.safety_two,
      type: data.type,
      bundle_id: data.bundle_id
    }
  })
}

const registerDev = (context, action, requestItems) => {
  const { data = {}} = requestItems

  return ({
    url: `${apiBaseUrl}/register_dev`,
    method: 'POST',
    data: {
      email: data.email,
      name: data.name,
      password: data.password,
      safety_one: this.form.safety_one,
      safety_two: this.form.safety_two,
      type: data.type,
      bundle_id: data.bundle_id
    }
  })
}

const emailVerify = (context, action, requestItems) => {
  const { data = {}} = requestItems

  return ({
    url: `${apiBaseUrl}/email_verification/send`,
    method: 'POST',
    data: {
      email: data.email
    }
  })
}

const login = (context, action, requestItems) => {
  const { data = {}} = requestItems

  return ({
    url: `${apiBaseUrl}/login`,
    method: 'POST',
    data: {
      email: data.email,
      password: data.password,
      type: data.type,
      bundle_id: data.bundle_id
    }
  })
}

const socialLogin = (context, action, requestItems) => {
  const { data = {}} = requestItems

  return ({
    url: `${apiBaseUrl}/tokenlogin`,
    method: 'POST',
    data: {
      type: data.type,
      bundle_id: data.bundle_id
    }
  })
}

const logout = (context, action, requestItems) => {
  const { data = {}} = requestItems
  return ({
    url: `${apiBaseUrl}/auth/logout`,
    method: 'POST',
    data: {
      type: data.type
    }
  })
}

const resetPassword = (context, action, requestItems) => {
  const { data = {}} = requestItems
  return ({
    url: `${apiBaseUrl}/auth/user-password/reset`,
    method: 'POST',
    data: {
      password: data.password
    }
  })
}

const resetMsPassword = (context, action, requestItems) => {
  const { data = {}} = requestItems
  return ({
    url: `${apiBaseUrl}/resetPassword`,
    method: 'POST',
    data: {
      email: data.email,
      token: data.token,
      password: data.password
    }
  })
}

const userProfile = (context, action, requestItems) => {
  return ({
    url: `${apiBaseUrl}/auth/user-profile`,
    method: 'POST'
  })
}

const userDelete = (context, action, requestItems) => {
  return ({
    url: `${apiBaseUrl}/auth/user/remove`,
    method: 'DELETE'
  })
}

const forgetPasswordEmail = (context, action, requestItems) => {
  const { data = {}} = requestItems

  return ({
    url: `${apiBaseUrl}/email_verification/reset`,
    method: 'POST',
    data: {
      email: data.email
    }
  })
}

const getCheckPrivacy = (context, action, requestItems) => {
  const { data = {}} = requestItems

  return ({
    url: `${apiBaseUrl}/privacy/check`,
    method: 'GET',
    params: {
      type: data.type,
      bundle_id: data.bundle_id
    }
  })
}

const postCheckPrivacy = (context, action, requestItems) => {
  const { data = {}} = requestItems

  return ({
    url: `${apiBaseUrl}/privacy/read`,
    method: 'POST',
    data: {
      type: data.type,
      bundle_id: data.bundle_id
    }
  })
}

const forgetPassword = (context, action, requestItems) => {
  const { data = {}} = requestItems

  return ({
    url: `${apiBaseUrl}/forgetPassword`,
    method: 'POST',
    data: {
      email: data.email,
      safety_one: data.safety_one,
      safety_two: data.safety_two
    }
  })
}

const loginFromManager = (context, action, requestItems) => {
  const { headers = {}} = requestItems

  return ({
    url: `${apiBaseUrl}/manager/token-login-front`,
    method: 'POST',
    headers
  })
}

const shopperLoginWithToken = (context, action, requestItems) => {
  const { data = {}} = requestItems

  return ({
    url: `${apiBaseUrl}/shopper-tokenlogin`,
    method: 'POST',
    data: {
      type: data.type,
      bundle_id: data.bundle_id
    }
  })
}

const shopperLogin = (context, action, requestItems) => {
  const { data = {}} = requestItems

  return ({
    url: `${apiBaseUrl}/shopper-login`,
    method: 'POST',
    data
  })
}

export default {
  CHECK_USER_STATUS: checkUserStatus,
  REGISTER: register,
  REGISTER_DEV: registerDev,
  EMAIL_VERIFY: emailVerify,
  LOGIN: login,
  SOCIAL_LOGIN: socialLogin,
  LOGOUT: logout,
  RESET_PASSWORD: resetPassword,
  RESET_MS_PASSWORD: resetMsPassword,
  USER_PROFILE: userProfile,
  USER_DELETE: userDelete,
  FORGET_PASSWORD_EMAIL: forgetPasswordEmail,
  GET_CHECK_PRIVACY: getCheckPrivacy,
  POST_CHECK_PRIVACY: postCheckPrivacy,
  FORGET_PASSWORD: forgetPassword,
  LOGIN_FROM_MANAGER: loginFromManager,
  SHOPPER_LOGIN_WITH_TOKEN: shopperLoginWithToken,
  SHOPPER_LOGIN: shopperLogin
}
