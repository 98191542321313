<template>
  <div v-if="!isManager" class="member-center-btn">
    <div
      v-for="(btn, index) in btns"
      :key="index"
      class="btn-block"
      :class="handleActiveClass(index)"
      @click="handleChangePage(btn.link)"
    >
      <div class="btn-item">
        <div class="icon-wrapper">
          <img
            v-if="index === isActive"
            class="btn-image"
            :src="btn.activeIcon"
          >
          <img
            v-else
            class="btn-image"
            :src="btn.normalIcon"
          >
          <div
            v-if="index === 1 && pendingCount > 0"
            class="bubble-icon"
          >
            <div class="bubble">
              {{ pendingCount }}
            </div>
          </div>
        </div>
        <div
          class="btn-text"
        >
          {{ btn.btnText }}
        </div>
      </div>
      <div
        v-if="index === isActive"
        class="line"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  props: {
    isActive: {
      type: Number,
      default: 0
    }
  },
  computed: {
    ...mapGetters({
      isManager: 'user/isManager',
      pendingCount: 'consumption/pendingCount'
    }),
    btns() {
      return [
        {
          normalIcon: require('@/assets/image/icon-nav-1.svg'),
          activeIcon: require('@/assets/image/icon-nav-1-.svg'),
          btnText: '會員中心',
          link: '/ms-web/member-center'
        },
        {
          normalIcon: require('@/assets/image/icon-nav-2.svg'),
          activeIcon: require('@/assets/image/icon-nav-2-.svg'),
          btnText: '消費紀錄',
          link: '/ms-web/consumption-records'
        },
        {
          normalIcon: require('@/assets/image/icon-nav-3-.svg'),
          activeIcon: require('@/assets/image/icon-nav-3.svg'),
          btnText: '紅利兌換',
          link: '/ms-web/point-award'
        }
      ]
    }
  },
  mounted() {
    this.getPendingNumber()
  },
  methods: {
    getPendingNumber() {
      this.$store.dispatch('consumption/fetchPendingList')
    },
    handleActiveClass(index) {
      if (index === this.isActive) {
        return 'active'
      }
    },
    handleChangePage(link) {
      this.$router.push(link)
    }
  }
}
</script>

<style lang="scss" scoped>
.member-center-btn {
  position: fixed;
  bottom: 0;
  right: 0px;
  left: 0px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background-image: url('~@/assets/image/bg1.png');
  background-position-y: bottom;
  background-size: cover;
  padding-top: 12px;
  z-index: 99;

  .btn-block {
    width: 33.33333%;
    text-align: center;
    .btn-text {
      font-size: 14px;
      font-weight: blod;
      padding-bottom: 5px;
    }

    &.active {
      .btn-text {
        color: $primary;
      }
    }
  }
  .btn-image {
    width: 18px;
    height: 18px;
  }
  .line {
    height: 5px;
    background-color: $primary;
  }
  .icon-wrapper {
    position: relative;
  }
  .bubble-icon {
    position: absolute;
    top: -8px;
    right: 40px
  }
  .bubble {
    height:18px;
    width:18px;
    border-radius: 50%;
    background-color: red;
    box-shadow: 2px 2px 1px 1px rgba(20, 80, 209, 0.2);

    color: #ffffff;
    font-size: 12px;
    font-weight: bold;
    line-height: 18px;
  }
}
</style>
