<template>
  <div class="winner-list container">
    <PageTitle back="/ms-web/point-award">
      得獎名單
    </PageTitle>
    <div class="award-wrapper">
      <div
        v-for="(event, index) in events"
        :key="index"
        class="award-block"
      >
        <div class="detail-block">
          <div class="image-wrapper">
            <img :src="getPhoto(event)">
          </div>
          <div class="detail">
            <div class="name">
              {{ event.name }}
            </div>
            <div class="date">
              開獎日：<br>{{ getTime(event.lottery_at) }}
            </div>
          </div>
        </div>
        <div
          v-if="showList(event) && lottery(event)"
          class="receive-btn"
          @click="handleViewList(event)"
        >
          查看
        </div>
        <div
          v-if="wait(event)"
          class="wait-btn"
        >
          待開獎
        </div>
      </div>
      <ListDialog
        :is-open.sync="isOpenList"
        :data="data"
      />
    </div>
  </div>
</template>

<script>
import _ from 'lodash'
import dayjs from 'dayjs'
import api from '@/utils/api'
import helpers from '@/utils/helpers'
import ListDialog from '@/components/point/ListDialog.vue'
export default {
  components: { ListDialog },
  data() {
    return {
      data: {},
      isOpenList: false,
      events: []
    }
  },
  mounted() {
    this.fetchList()
  },
  methods: {
    lottery(event) {
      console.log('event', event)
      if (dayjs().isAfter(event.lottery_at)) {
        return true
      } else {
        return false
      }
    },
    wait(event) {
      var isSameOrBefore = require('dayjs/plugin/isSameOrBefore')
      dayjs.extend(isSameOrBefore)
      if (dayjs().isSameOrBefore(event.lottery_at)) {
        console.log('wait', dayjs().isSameOrBefore(event.lottery_at))
        return true
      } else {
        return false
      }
    },
    showList(event) {
      return !!event.winners
    },
    getTime(datetime) {
      return helpers.getDatetime(datetime)
    },
    getPhoto(event) {
      const url = _.get(event, 'photos.0.url', '')
      if (!url) return require('@/assets/image/icon-gift.svg')
      return helpers.getUrl(url)
    },
    handleViewList(event) {
      this.isOpenList = true
      this.data = event
    },
    fetchList() {
      api('GET_AWARD_WINNER').then((response) => {
        const { status, message, data } = response.data

        if (!status) {
          return this.$toast.open({
            message: this.$error.getMessage(message),
            type: 'error',
            duration: 5000
          })
        }

        const list = _.get(data, '0', [])
        this.events = list
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.winner-list {
  .award-block {
    background-color: #fff;
    padding: 10px;
    width: 100%;
    margin-bottom: 10px;

    border-radius: 6px;
    box-shadow: 2px 2px 8px rgba(0,0,0,0.2);

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
  .detail-block {
    width: 76%;
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .image-wrapper {
    width: 60px;
    height: 60px;
    margin-right: 6px;
    overflow: hidden;
    border-radius: 6px;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  .detail {
    padding: 10px 0;
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: space-between;
  }
  .name {
    font-size: 16px;
    font-weight: bold;
  }
  .date {
    font-size: 14px;
    text-align: left;
  }
  .receive-btn {
    display: flex;
    align-items: flex-end;
    padding: 4px 16px;
    font-size: 14px;
    font-weight: bold;
    background-color: #5AC5C0;
    border-radius: 14px;
    color: #fff;
    white-space: nowrap;
  }
  .wait-btn {
    display: flex;
    align-items: flex-end;
    padding: 4px 16px;
    font-size: 14px;
    font-weight: bold;
    background-color: #BDBDBD;
    border-radius: 14px;
    color: #fff;
    white-space: nowrap;
  }
}
</style>
