<template>
  <div id="upload-invoice">
    <div class="container">
      <PageTitle :back="backUrl">
        上傳發票照片
      </PageTitle>
      <div class="notice">
        請上傳清晰、可辨識的發票照片，內容須包含發票號碼、購買日期以及購買明細（照片上傳上限為10張）
      </div>
      <div
        v-if="!preview"
        class="sample-block"
      >
        <div class="image-label">
          照片範例
        </div>
        <div class="upload-box">
          <img
            class="sample-image"
            :src="sampleUrl"
          >
        </div>
        <div class="notice">
          審核時間約3-5個工作日，謝謝！
        </div>
      </div>
      <div class="preview-section">
        <PreviewBlock
          v-if="preview"
          class="preview-block"
          :images="imageUrls"
        />
      </div>
      <div class="bottom-btn">
        <label
          slot="upload-label"
          for="fileInput"
        >
          <v-btn
            v-if="!preview"
            outlined
            block
            color="primary"
            elevation="1"
          >
            <p class="upload-text">
              上傳發票照片
            </p>
            <!-- <ImageUploader
              class="image-uploader"
              :quality="0.7"
              :preview="false"
              output-format="file"
              accept="image/*"
              @input="handleUploadMultiple"
            /> -->
            <v-file-input
              counter
              show-size
              multiple
              accept="image/*"
              @change="handleUploadMultiple"
            />
          </v-btn>
        </label>
      </div>
      <div
        v-if="preview"
        class="bottom-btn"
      >
        <Btn
          class="btn"
          btn-text="送出"
          @click="handleSubmit"
        />
        <label
          slot="upload-label"
          for="fileInput"
        >
          <v-btn
            outlined
            block
            color="primary"
            elevation="1"
          >
            <p class="upload-text">
              重新選擇所有照片
            </p>
            <!-- <ImageUploader
              class="image-uploader"
              :quality="0.7"
              :preview="false"
              output-format="file"
              accept="image/*"
              @input="handleUploadMultiple"
            /> -->
            <v-file-input
              :counter="10"
              show-size
              multiple
              accept="image/*"
              @change="handleUploadMultiple"
            />
          </v-btn>
        </label>
      </div>
    </div>
  </div>
</template>

<script>
import _ from 'lodash'
import api from '@/utils/api'
import { mapActions } from 'vuex'
import imageCompression from 'browser-image-compression'
import PreviewBlock from '@/components/consumption/PreviewBlock'

export default {
  components: { PreviewBlock },
  data() {
    return {
      sampleUrl: require('@/assets/image/demo-invoice.jpeg'),
      imageUrls: [],
      preview: false,
      image: '',
      files: [],

      form: {
        images: []
      }
    }
  },
  computed: {
    backUrl() {
      const { no } = this.$route.params
      return `/ms-web/consumption-records/${no}`
    }
  },
  mounted() {
    this.fetchOrder()
  },
  methods: {
    ...mapActions({
      setUpdateDataToList: 'consumption/setUpdateDataToList'
    }),
    async fetchOrder() {
      this.$root.loading = true
      try {
        const { no } = this.$route.params
        const response = await api('GET_ORDER', { id: no })
        const { status } = response.data
        if (!status) {
          this.$toast.open({
            message: '查無此訂單',
            type: 'error'
          })

          this.$router.push('/ms-web/consumption-records')
          return
        }

        this.form.order_id = no
      } catch (error) {
        console.log('error', error)
      }
      this.$root.loading = false
    },
    handleUploadMultiple(files) {
      if (files.length > 10) {
        this.$toast.open({
          message: '只能選擇10個檔案',
          type: 'error'
        })
        return
      }
      // Init files
      this.form.images = []
      this.imageUrls = []

      files.map((file) => {
        this.handleUpload(file)
      })
      this.preview = true
    },
    async handleUpload(file) {
      const url = URL.createObjectURL(file)
      this.imageUrls.push(url)

      const resizeImage = await this.resizeImage(file)
      this.form.images.push(resizeImage)
    },
    async resizeImage(file) {
      const options = {
        maxSizeMB: 2,
        initialQuality: 1,
        maxWidthOrHeight: 1200,
        useWebWorker: true
      }
      try {
        const compressedFile = await imageCompression(file, options)
        return compressedFile
      } catch (error) {
        console.log('error', error)
      }
    },
    async handleSubmit() {
      this.$root.loading = true
      try {
        var formData = new FormData()
        _.forEach(this.form, (value, key) => {
          if (Array.isArray(value)) {
            for (let index = 0; index < value.length; index++) {
              const element = value[index]
              formData.append(`${key}[]`, element)
            }
          } else {
            formData.append(key, value)
          }
        })

        const resposne = await api('REVIEW_SEND', { data: formData })
        const { status, message } = resposne.data
        if (!status) {
          this.$root.loading = false
          this.$toast.open({
            message: this.$error.getMessage(message),
            type: 'error'
          })

          return
        }

        this.$toast.open({
          message: '送出成功',
          type: 'success'
        })

        const { no } = this.$route.params
        this.$router.push(`/ms-web/consumption-records/${no}`)
      } catch (error) {
        console.log('error', error)
      }
      this.$root.loading = false
    }
  }
}
</script>

<style lang="scss" scoped>
#upload-invoice {
  .container {
    padding-bottom: 150px;
  }
  // .preview-section {
  //   margin-top: 50px;
  // }
  .upload-box {
    border: 1px solid $background-light;
    border-radius: 12px;
    overflow: hidden;
  }
  .preview-block{
    margin-top: 40px;
  }
  .notice {
    text-align: left;
    font-size: 16px;
    color: $text-1;
    &:last-child {
      padding-top: 20px;
    }
  }
  .sample-block {
    margin-top: 15px;
  }
  .image-label {
    text-align: left;
    padding-bottom: 8px;
    font-size: 16px;
    color: $text-1;
  }
  .sample-image {
    width: 100%;
  }
  .btn {
    padding-bottom: 15px;
  }
  .upload-text {
    margin-bottom: 0;
  }
  &::v-deep .v-input {
    position: absolute;
    opacity: 0;
    width: 100%;
  }

  &::v-deep .v-text-field {
    padding-top: 0;
  }

  &::v-deep .image-uploader {
    position: absolute;

    .fileinput {
      opacity: 0;
    }
  }
}
</style>
