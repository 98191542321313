import { render, staticRenderFns } from "./point-record.vue?vue&type=template&id=232cc1ba&scoped=true&"
import script from "./point-record.vue?vue&type=script&lang=js&"
export * from "./point-record.vue?vue&type=script&lang=js&"
import style0 from "./point-record.vue?vue&type=style&index=0&id=232cc1ba&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "232cc1ba",
  null
  
)

export default component.exports