import _ from 'lodash'
import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters({
      userData: 'user/data'
    }),
    isBlue() {
      return this.cardLevel === 'blue'
    },
    isGold() {
      return this.cardLevel === 'gold'
    },
    isBlack() {
      return this.cardLevel === 'black'
    },
    cardLevel() {
      const level = _.get(this.userData, 'level', 'blue')
      if (['blue', 'gold', 'black'].includes(level)) return level
      return 'blue'
    },
    currentRank() {
      return this.ranks.find((rank) => rank.id === this.cardLevel) || {}
    },
    ranks() {
      return [
        {
          id: 'blue',
          label: '藍卡會員',
          point: 0,
          image: require('@/assets/image/card-3.svg')
        },
        {
          id: 'gold',
          label: '金卡會員',
          point: 100,
          image: require('@/assets/image/card-2.svg')
        },
        {
          id: 'black',
          label: '黑卡會員',
          point: 200,
          image: require('@/assets/image/card-1.svg')
        }
      ]
    },
    nextRank() {
      const currentRank = this.currentRank
      const nextRank = this.ranks.find((rank) => rank.point > currentRank.point)
      return nextRank || {}
    },
    nextPointGap() {
      const currentPoint = this.currentRank.point
      const nextPoint = this.nextRank.point
      return nextPoint - currentPoint
    }
  }
}
