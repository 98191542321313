<template>
  <div id="member-info">
    <div class="container">
      <PageTitle back>
        會員資料
      </PageTitle>
      <div class="info-block">
        <div
          v-for="(info, index) in infos"
          :key="index"
          class="info-item"
        >
          <div class="info-title">
            {{ info.title }}
          </div>
          <div class="infomation">
            {{ info.content }}
          </div>
        </div>
      </div>
      <div class="operation-block">
        <div
          v-for="(item, index) in items"
          :key="index"
          class="operation-item"
          @click="handleClickItem(index)"
        >
          <div class="label-block">
            <img
              class="icon"
              :src="item.imageUrl"
            >
            <div class="label">
              {{ item.label }}
            </div>
          </div>
          <img
            class="icon-right"
            :src="require('@/assets/image/icon-right.svg')"
          >
        </div>
      </div>
    </div>
    <Dialog
      confirmation-text="確定要登出嗎？"
      :is-open.sync="isOpenLoginDialog"
      @confirm="handleLogout"
    />
    <Dialog
      confirmation-text="請確認是否刪除帳號，刪除後所有資料皆無法復原"
      :is-open.sync="isOpenDeleteAccountDialog"
      @confirm="handleDeleteAccount"
    />
    <Dialog
      confirmation-text="再次確認是否刪除帳號"
      :is-open.sync="isOpenDeleteConfirm"
      @confirm="deleteAccount"
    />
  </div>
</template>

<script>
import _ from 'lodash'
import Cookies from 'js-cookie'
import authMixins from '@/mixins/auth'
import api from '@/utils/api'

export default {
  mixins: [authMixins],
  data() {
    return {
      memberLevel: '白金會員',

      isOpenLoginDialog: false,
      isOpenDeleteAccountDialog: false,
      isOpenDeleteConfirm: false
    }
  },
  computed: {
    infos() {
      return [
        {
          title: '帳號',
          content: this.userData.email
        },
        {
          title: '會員ID',
          content: this.userData.id
        },
        {
          title: '會員等級',
          content: this.level
        }
      ]
    },
    items() {
      return [
        {
          imageUrl: require('@/assets/image/icon-corg.svg'),
          label: '更改密碼'
        },
        {
          imageUrl: require('@/assets/image/icon-logout.svg'),
          label: '登出帳號'
        },
        {
          imageUrl: require('@/assets/image/icon-close.svg'),
          label: '帳號刪除'
        }
      ]
    },
    level() {
      if (this.cardLevel === 'black') {
        return '黑卡會員'
      }
      if (this.cardLevel === 'gold') {
        return '金卡會員'
      }
      if (this.cardLevel === 'blue') {
        return '藍卡會員'
      }
      return ''
    },
    cardLevel() {
      const level = _.get(this.userData, 'level', 'blue')
      console.log('level', level)

      if (['blue', 'gold', 'black'].includes(level)) {
        return level
      }

      return 'blue'
    }
  },
  methods: {
    handleClickItem(index) {
      if (index === 0) {
        this.$router.push('/ms-web/reset-password')
        return
      }
      if (index === 1) {
        this.isOpenLoginDialog = true
        return
      }
      if (index === 2) {
        this.isOpenDeleteAccountDialog = true
        return
      }
    },
    handleLogout() {
      this.$loading.start()
      try {
        api('LOGOUT', {
          data: {
            type: this.authData.provider
          }
        })
        this.removeJwtToken()
        this.$store.dispatch('user/resetData')
        this.$store.dispatch('auth/resetData')
        this.$router.push('/ms-web/logout')
      } catch (error) {
        console.log('error', error)
      }

      this.$loading.finish()
    },
    handleDeleteAccount() {
      this.isOpenDeleteConfirm = true
      return
    },
    deleteAccount() {
      this.$loading.start()
      api('USER_DELETE').then(() => {
        this.removeJwtToken()
        Cookies.remove('is_read_statement')
        this.$store.dispatch('user/resetData')
        this.$router.push('/ms-web/destroy')
      }).then(() => {
        this.$loading.finish()
      })
    }
  }
}
</script>

<style lang="scss" scoped>
#member-info {
  .title-block {
    display: flex;
    flex-direction: row;
    padding-bottom: 15px;
  }
  .page-title {
    padding-bottom: 0;
    padding-left: 120px;
  }
  .left-icon {
    width: 10px;
  }
  .info-block {
    text-align: left;
    padding-bottom: 5px;
  }
  .info-item {
    padding-bottom: 12px;
  }
  .info-title {
    font-size: 14px;
    color: $text-1;
  }
  .infomation {
    font-size: 16px;
    font-weight: bold;
    line-height: 30px;
    color: $primary;
    border-bottom: 1px solid;
    border-color: $background-light;
  }
  .operation-item {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-left: 5px;
    padding-right: 5px;
    margin-bottom: 10px;
    border-bottom: 1px solid;
    border-color: $background-light;
  }
  .icon {
    width: 15px;
  }
  .icon-right {
    width: 8px;
  }
  .label-block {
    display: flex;
    flex-direction: row;
    margin-bottom: 5px;
  }
  .label {
    padding-left: 8px;
    color: $text-1;
    font-size: 14px;
    // font-weight: bold;
  }
}
</style>
