<template>
  <div class="page-title-block">
    <div
      v-if="back"
      class="back-btn"
      @click="handleBack"
    >
      <img
        class="left-icon"
        :src="require('@/assets/image/icon-left.svg')"
      >
    </div>
    <div class="page-title">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    back: {
      type: [Boolean, String],
      default: false
    }
  },
  methods: {
    handleBack() {
      if (typeof this.back === 'string') {
        return this.$router.push(this.back)
      }

      this.$router.back()
    }
  }
}
</script>

<style lang="scss" scoped>
.page-title-block {
  position: relative;
  display: flex;
  margin-bottom: 16px;

  .left-icon {
    width: 10px;
  }

  .back-btn {
    position: absolute;
    left: 0;
    top: 50%;
    padding: 0 8px;
    transform: translateY(-50%);
  }

  .page-title {
    width: 100%;
    padding-bottom: 0 !important;
  }
}
</style>
