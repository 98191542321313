<template>
  <div id="signup-page">
    <div
      v-if="step === 0"
      class="container"
    >
      <v-form
        ref="signFormRef"
        lazy-validation
        class="signup-wrapper"
        @submit.prevent="handleNext"
      >
        <div class="page-title">
          會員註冊
        </div>
        <VInput
          readonly
          type="email"
          :value="userData.email"
          input-title="帳號"
        />
        <VInput
          required
          input-title="暱稱（請勿使用真實姓名）"
          :value.sync="form.name"
          :rules="[
            rules.required
          ]"
        />
        <VInput
          required
          input-title="設定密碼"
          label="請輸入8-20碼英數混合字"
          type="password"
          :value.sync="form.password"
          :rules="[
            rules.password
          ]"
        />
        <VInput
          required
          :value.sync="form.confirmPassword"
          input-title="再次輸入密碼"
          type="password"
          :rules="[
            rules.password
          ]"
        />
        <Btn
          class="bottom-btn"
          type="submit"
          btn-text="下一步"
        />
      </v-form>
    </div>
    <div
      v-if="step === 1"
      class="container"
    >
      <v-form
        ref="questionFormRef"
        lazy-validation
        class="signup-wrapper"
        @submit.prevent="handleSignup"
      >
        <div class="page-title">
          安全提示問題
        </div>
        <div class="notice">
          若您日後忘記密碼，以下問題將用來驗證您的身份，協助您重新設定密碼。
        </div>
        <div class="title-notice">
          安全提示問題1
        </div>
        <VInput
          required
          :value.sync="form.safety_one"
          input-title="請問您養的第一個寵物叫什麼名字？"
          label="請輸入答案"
          :rules="[
            rules.required
          ]"
        />
        <div class="title-notice">
          安全提示問題2
        </div>
        <VInput
          required
          input-title="請問您18歲生日收到什麼禮物？"
          label="請輸入答案"
          :value.sync="form.safety_two"
          :rules="[
            rules.required
          ]"
        />
        <Btn
          class="bottom-btn"
          type="submit"
          btn-text="註冊"
        />
      </v-form>
    </div>
  </div>
</template>

<script>
import Cookies from 'js-cookie'
import { mapActions } from 'vuex'
import api from '@/utils/api'

import authMixins from '@/mixins/auth'
import formMixins from '@/mixins/form'

export default {
  mixins: [authMixins, formMixins],
  data() {
    return {
      step: 0,
      form: {
        name: null,
        password: null,
        confirmPassword: null,
        safety_one: null,
        safety_two: null
      }
    }
  },
  async mounted() {
    this.$root.loading = true

    const token = this.getJwtToken()
    if (token && !this.isLogin) {
      try {
        await this.$store.dispatch('user/fetchData')
      } catch (error) {
        console.log('error', error)
      }
    }

    if (this.isLogin) {
      return this.$router.push('/ms-web/member-center')
    }

    if (!this.userData.email) {
      return this.$router.push('/ms-web/login')
    }

    this.$root.loading = false
  },
  methods: {
    ...mapActions({
      setUserData: 'user/setData'
    }),
    handleNext() {
      const result = this.$refs.signFormRef.validate()
      if (!result) {
        return
      }

      this.$loading.start()
      if (this.form.password !== this.form.confirmPassword) {
        this.$loading.finish()
        this.$toast.open({
          message: '密碼和確認密碼不一致',
          type: 'error'
        })

        return
      }
      this.$loading.finish()
      this.step = 1
    },
    handleSignup() {
      const result = this.$refs.questionFormRef.validate()
      if (!result) {
        return this.$toast.open({
          message: '請確認資料是否輸入完成',
          type: 'error'
        })
      }

      this.$loading.start()
      api('REGISTER', {
        data: {
          email: this.userData.email,
          name: this.form.name,
          password: this.form.password,
          safety_one: this.form.safety_one,
          safety_two: this.form.safety_two,
          type: this.authData.provider,
          bundle_id: this.authData.bundle_id
        }
      }).then((response) => {
        const { data, message, status } = response.data

        if (message && !status) {
          return this.$toast.open({
            message: this.$error.getMessage(message),
            type: 'error'
          })
        }

        Cookies.set('ms_access_token', data.access_token)
        this.setUserData(data.user)

        this.$router.push('/ms-web/signup-success')
      }).then(() => {
        this.$loading.finish()
      })
    }
  }
}
</script>

<style lang="scss" scoped>
#signup-page {
  min-height: 100vh;

  .btn {
    position: fixed;
    bottom: 23px;
    right: 23px;
    left: 23px;
  }
  .notice {
    text-align: left;
    color: $text-1;
    font-size: 14px;
    margin-bottom: 20px;
  }
  .title-notice {
    text-align: left;
    color: $text-1;
    font-size: 14px;
    margin-bottom: 6px;
  }
}
</style>
