import axios from 'axios'
import { getConfig } from '@/utils/api/config'

export default (action, requestItems = {}) => {
  const requestConfig = getConfig(this, action, requestItems)
  if (!requestConfig) {
    return
  }

  return axios(requestConfig)
}
