<template>
  <div id="email-confirm">
    <div class="container">
      <div class="page-wrapper">
        <div class="notice">
          恭喜您完成會員註冊，<br>
          已正式啟用您的微軟帳號
        </div>
        <div class="btn-block">
          <Btn
            class="btn"
            btn-text="前往會員中心"
            @click="$router.push('/ms-web/member-center')"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import linkMixins from '@/mixins/link'

export default {
  mixins: [linkMixins],
  methods: {
    handleToMemberCenter() {}
  }
}
</script>

<style lang="scss" scoped>
#email-confirm {
  min-height: 100vh;
  .container {
    display:  flex;
    align-items: center;
    justify-content:  center;
  }
  .page-wrapper {
    width: 100%;
    padding: 0 30px;
  }
  .notice {
    color: $text-1;
    font-size: 20px;
    padding-bottom: 70px;
  }
  .btn {
    padding-bottom: 20px;
  }
}
</style>
