<template>
  <div id="reset-password">
    <div class="container is-relative">
      <v-form
        ref="form"
        lazy-validation
        @submit.prevent="handleResetPassword"
      >
        <PageTitle back>
          重新設定密碼
        </PageTitle>
        <VInput
          required
          input-title="設定密碼"
          label="請輸入8-20碼英數混合字"
          type="password"
          :value.sync="form.password"
          :rules="[
            rules.password
          ]"
        />
        <VInput
          required
          type="password"
          :value.sync="form.confirmPassword"
          input-title="再次輸入密碼"
          :rules="[
            rules.password
          ]"
        />
        <Btn
          class="bottom-btn"
          type="submit"
          btn-text="確認"
        />
      </v-form>
    </div>
  </div>
</template>

<script>
import authMixins from '@/mixins/auth'
import formMixins from '@/mixins/form'
import api from '@/utils/api'

export default {
  mixins: [authMixins, formMixins],
  data() {
    return {
      form: {
        password: null,
        confirmPassword: null
      },
      token: null,
      email: null
    }
  },
  methods: {
    async handleResetPassword() {
      const result = this.$refs.form.validate()
      if (!result) {
        return
      }

      this.$loading.start()
      if (this.form.password !== this.form.confirmPassword) {
        this.$loading.finish()
        this.$toast.open({
          message: '密碼和確認密碼不一致',
          type: 'error'
        })

        return
      }

      try {
        const response = await api('RESET_PASSWORD', {
          data: {
            password: this.form.password
          }
        })

        const { status, message } = response.data

        if (message && !status) {
          this.$loading.finish()
          this.$toast.open({
            message: this.$error.getMessage(message),
            type: 'error'
          })

          return
        }

        this.$toast.open({
          message: '更新密碼成功',
          type: 'success'
        })
        this.$router.push('/ms-web/member-center')
      } catch (error) {
        console.log('error', error)
      }

      this.$loading.finish()
    }
  }
}
</script>

<style lang="scss" scoped>
#reset-password {
  min-height: 100vh;

  .btn {
    position: fixed;
    bottom: 23px;
    right: 23px;
    left: 23px;
  }
}
</style>
