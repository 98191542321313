<template>
  <div id="email-validate">
    <div class="container">
      <div class="email-wrapper">
        <div class="page-title">
          電子信箱驗證
        </div>
        <div class="notice">
          會員註冊的驗證信件已寄送至<br>
          {{ user.email }}<br>
          <br>
          請收信完成驗證流程啟動帳號，<br>
          歡迎您加入會員！
        </div>
        <div class="btn-block">
          <Btn
            class="btn"
            btn-text="重新發送"
            @click="isOpenReSendDialog = true"
          />
          <Btn
            btn-text="電子信箱錯誤"
            @click="isOpenReSignupDialog = true"
          />
        </div>
      </div>
    </div>
    <Dialog
      confirmation-text="確認要重新發送驗證信嗎？"
      :is-open.sync="isOpenReSendDialog"
      @confirm="handleReSend"
    />
    <Dialog
      confirmation-text="電子信箱錯誤，要換一個信箱重新註冊嗎？"
      :is-open.sync="isOpenReSignupDialog"
      @confirm="handleReSignup"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import api from '@/utils/api'

export default {
  data() {
    return {
      isOpenReSendDialog: false,
      isOpenReSignupDialog: false
    }
  },
  computed: {
    ...mapGetters({
      user: 'user/data'
    })
  },
  mounted() {
    if (!this.user.email) {
      this.$root.loading = true
      setTimeout(() => {
        this.$router.push('/ms-web/login')
      }, 200)
    }
  },
  methods: {
    handleReSend() {
      this.$loading.start()
      api('EMAIL_VERIFY', {
        data: {
          email: this.user.email
        }
      }).then((response) => {
        const { status, message } = response.data

        if (message && !status) {
          return this.$toast.open({
            message: this.$error.getMessage(message),
            type: 'error'
          })
        }

        this.$toast.open({
          message: '驗證信已重新寄出',
          type: 'success'
        })
      }).then(() => {
        this.$loading.finish()
      })
    },
    handleReSignup() {
      this.$store.dispatch('user/resetData')
      this.$router.push('/ms-web/login')
    }
  }
}
</script>

<style lang="scss" scoped>
#email-validate {

  .notice {
    padding: 80px 40px;
    color: $text-1;
    font-size: 14px;
  }
  .btn-block {
    position: fixed;
    bottom: 23px;
    right: 23px;
    left: 23px;
    .btn {
      padding-bottom: 15px;
    }
  }
}
</style>
