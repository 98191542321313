<template>
  <div id="email-confirm">
    <div class="container">
      <img
        class="logo"
        :src="require('@/assets/image/logo.svg')"
        @click="$router.push('/ms-web/login')"
      >
      <div class="page-wrapper">
        <div class="notice">
          已更新密碼成功
        </div>
        <div class="btn-block">
          <Btn
            class="btn"
            btn-text="我要登入"
            @click="handleToLogin"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import linkMixins from '@/mixins/link'

export default {
  mixins: [linkMixins],
  methods: {
    handleToLogin() {
      this.$router.push('/ms-web/login')
    }
  }
}
</script>

<style lang="scss" scoped>
#email-confirm {
  min-height: 100vh;

  .logo {
    padding-bottom: 150px;
    transform: translateY(-80px);
  }
  .container {
    min-height: 100vh;
    display:  flex;
    flex-direction: column;
    align-items: center;
    justify-content:  center;
  }
  .page-wrapper {
    width: 100%;
    padding: 0 30px;
  }
  .notice {
    color: $text-1;
    font-size: 20px;
    padding-bottom: 70px;
  }
  .btn {
    padding-bottom: 20px;
  }
}
</style>
