<template>
  <div id="login-page">
    <div class="container is-relative">
      <v-form
        v-if="isMember"
        ref="loginForm"
        class="page-wrapper"
        lazy-validation
        @submit.prevent="handleLogin"
      >
        <div class="page-title">
          會員登入
        </div>
        <VInput
          readonly
          type="email"
          :value="form.email"
          input-title="帳號"
        />
        <VInput
          required
          type="password"
          :value.sync="form.password"
          input-title="密碼"
          label="請輸入密碼"
          :rules="[
            rules.password
          ]"
        />
        <div class="forget">
          <p @click="handleAnswerQuestion">
            忘記密碼
          </p>
        </div>
        <Btn
          class="bottom-btn"
          type="submit"
          btn-text="登入"
        />
        <!-- <Dialog
          confirmation-text="將發送驗證信至您的信箱"
          :is-open.sync="isOpenDialog"
          @confirm="handleForgetPassword"
        /> -->
      </v-form>

      <v-form
        v-else
        ref="checkEmailForm"
        lazy-validation
        class="page-wrapper"
        @submit.prevent="checkEmailStatus"
      >
        <div>
          <div class="page-title">
            會員註冊／登入
          </div>
          <div class="login-content">
            <VInput
              required
              type="email"
              :value.sync="form.email"
              input-title="帳號"
              label="請輸入電子信箱"
              :rules="[
                rules.email,
              ]"
            />
            <div class="notice-block">
              <p class="notice-text">
                *如您已成為微軟會員，請輸入當時註冊的電子信箱
              </p>
              <p class="notice-text">
                *每個會員帳號僅能綁定一組LINE/Facebook帳號
              </p>
            </div>
          </div>
        </div>
        <Btn
          class="bottom-btn"
          type="submit"
          btn-text="下一步"
        />
      </v-form>
    </div>
    <!-- <Dialog
      confirmation-text="尚未完成驗證，要重新發送驗證信嗎？"
      :is-open.sync="isOpenReSendDialog"
      @confirm="handleReSend"
    /> -->
    <StatementDialog
      :open.sync="isOpenStatementDialog"
      @confirm="handleReadStatement"
    />
  </div>
</template>

<script>
import Cookies from 'js-cookie'
import { mapGetters, mapActions } from 'vuex'
import api from '@/utils/api'

import authMixins from '@/mixins/auth'
import formMixins from '@/mixins/form'
import StatementDialog from '@/components/login/StatementDialog'

export default {
  components: { StatementDialog },
  mixins: [authMixins, formMixins],
  data() {
    return {
      isMember: false,
      isOpenDialog: false,
      isOpenReSendDialog: false,
      isOpenStatementDialog: false,
      link: '/ms-web/reset-password-send',
      form: {
        email: null,
        password: null
      }
    }
  },
  computed: {
    ...mapGetters({
      isManager: 'user/isManager'
    })
  },
  async mounted() {
    this.$root.loading = true

    const token = this.getJwtToken()
    if (token && !this.isLogin) {
      try {
        await this.$store.dispatch('user/fetchData')
      } catch (error) {
        console.log('error', error)
      }
    }

    if (this.isManager) {
      this.$root.loading = false
      // 如果殘留店員的 token，則清空
      this.removeJwtToken()
      this.$store.dispatch('user/resetData')
      this.$store.dispatch('auth/resetData')
      return
    }

    if (this.isLogin) {
      this.$router.push('/ms-web/member-center')
      this.$root.loading = false
      return
    }

    if (this.isWeb) {
      await this.initLine()
      await this.initFacebook()
    }

    this.$root.loading = false
  },
  methods: {
    ...mapActions({
      setUserData: 'user/setData',
      setUserDataByKey: 'user/setDataByKey'
    }),
    handleReadStatement() {
      if (this.isWeb) {
        return
      }

      api('POST_CHECK_PRIVACY', {
        data: {
          type: this.authData.provider,
          bundle_id: this.authData.bundle_id
        }
      })
    },
    handleLogin() {
      this.$loading.start()
      api('LOGIN', {
        data: {
          email: this.form.email,
          password: this.form.password,
          type: this.authData.provider,
          bundle_id: this.authData.bundle_id
        }
      }).then((response) => {
        console.log('response.data', response.data)
        const { status, message, data } = response.data

        if (message && !status) {
          return this.$toast.open({
            message: this.$error.getMessage(message),
            type: 'error'
          })
        }

        Cookies.set('ms_access_token', data.access_token)
        this.setUserData(data.user)
        this.$toast.open({
          message: '登入成功',
          type: 'success'
        })
        this.$router.push('/ms-web/member-center')
      }).finally(() => {
        this.$loading.finish()
      })
    },
    checkEmailStatus() {
      const result = this.$refs.checkEmailForm.validate()
      if (!result) {
        return
      }

      this.$loading.start()
      api('CHECK_USER_STATUS', {
        data: {
          email: this.form.email,
          type: this.authData.provider,
          bundle_id: this.authData.bundle_id
        }
      }).then((response) => {
        const { message, status } = response.data
        if (message === 'USER_DOSE_NOT_EXIST' || message === 'USER_AND_ID_DOES_NOT_EXIST') {
          this.setUserDataByKey({
            key: 'email',
            value: this.form.email
          })
          this.toRegister()

          return
        }

        if (message === 'EMAIL_NOT_VERIFY') {
          // this.isOpenReSendDialog = true
          return
        }

        if (message && !status) {
          return this.$toast.open({
            message: this.$error.getMessage(message),
            type: 'error'
          })
        }

        this.isMember = true
        this.$toast.open({
          message: '驗證成功，請登入',
          type: 'success'
        })
      }).then(() => {
        this.$loading.finish()
      })
    },
    // handleOpenDialog() {
    //   this.isOpenDialog = true
    // },
    toRegister() {
      this.$router.push('/ms-web/signup')
    },
    handleAnswerQuestion() {
      const data = { email: this.form.email }
      this.setUserData(data)
      this.$router.push('/ms-web/forget-password')
    }
    // handleForgetPassword() {
    //   this.$loading.start()
    //   api('FORGET_PASSWORD_EMAIL', {
    //     data: {
    //       email: this.form.email
    //     }
    //   }).then((response) => {
    //     console.log('response.data', response.data)
    //     const { status, message, data } = response.data

    //     if (message && !status) {
    //       return this.$toast.open({
    //         message: this.$error.getMessage(message),
    //         type: 'error',
    //       })
    //     }

    //     this.setUserDataByKey({
    //       key: 'email',
    //       value: this.form.email
    //     })
    //     this.$router.push('/ms-web/reset-password-send')
    //   }).then(() => {
    //     this.$loading.finish()
    //   })
    // }
  }
}
</script>

<style lang="scss" scoped>
#login-page {
  min-height: 100vh;

  .forget {
    display: flex;
    justify-content: center;
    p {
      margin: 0;
      width: 60px;
      font-size: 14px;
      color: $text-2;
    }
  }

  .notice-text {
    font-size: 14px;
    color: $text-2;
    text-align: left;
    margin-bottom: 3px;
  }
}
</style>
