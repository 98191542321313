export default {
  computed: {
    rules() {
      return {
        email: v => /.+@.+\..+/.test(v) || 'Email 格式不正確',
        required: value => !!value || '必填',
        password: v => (v || '').length >= 8 || '密碼最少8碼'
      }
    }
  }
}
